import { Helmet } from 'react-helmet-async';
import { Container, Typography, Stack, Grid } from '@mui/material';
import { AccountProfile } from "../sections/account/AccountProfile";
import { AccountDetails } from "../sections/account/AccountDetails";
import { AccountVoucherCodes } from '../sections/account/AccountVoucherCodes';


export default function ProfilePage() {
  return (
    <>
      <Helmet>
        <title> HCN Perks | Profile </title>
      </Helmet>

      <Container maxWidth="lg">
        <Stack spacing={3}>
          <div>
            <Typography variant="h4">
              Profile
            </Typography>
          </div>
          <div>
            <Grid
              container
              spacing={3}
            >
              <Grid item xs={12} sm={3} md={3}>
                <AccountProfile />
              </Grid >
              <Grid item xs={12} sm={8} md={8}>
                <AccountDetails />
              </Grid>

            </Grid>
          </div>
          <div>
            <Grid
              container
              spacing={3}
            >
              <Grid item xs={12} sm={11} md={11}>
                <AccountVoucherCodes />
              </Grid>
            </Grid>
          </div>
        </Stack>
      </Container>
    </>
  );
}