import { Box, Chip, Divider, Modal, Typography } from '@mui/material';
import { modalClaimStyle } from '../../theme/modal';
import { formatVoucherText } from '../../utils/formatStrings';
import { fDateTime } from '../../utils/formatTime';
import { MembershipCard } from './MembershipCard';

export default function VoucherClaimModal({
    open,
    handleClose,
    claimedVoucher,
}) {
    return (
        <Modal
            open={open}
            onClose={handleClose}
            sx={{ overflow: 'scroll' }}
        >
            <Box sx={modalClaimStyle}>
                <MembershipCard />
                <Typography textAlign={'center'} variant="h5" sx={{ mt: 2 }} gutterBottom>
                    Voucher Successfully Claimed!
                </Typography>
                <Divider sx={{ my: 2 }}>
                    <Chip label="Details" />
                </Divider>
                <Typography variant='body2'>
                    Brand: <b>{claimedVoucher.brand || claimedVoucher.brandName}</b>
                </Typography>
                <Typography variant='body2'>
                    Name: <b>{claimedVoucher.name}</b>
                </Typography>
                <Typography variant='body2'>
                    Code: <b>{claimedVoucher.code ? claimedVoucher.code : "-"}</b>
                </Typography>
                <Typography variant='body2' gutterBottom>
                    Claimed: <b>{fDateTime(Date.now())}</b>
                </Typography>
                <Divider sx={{ my: 3 }}>
                    <Chip label="How to redeem" />
                </Divider>
                <Typography gutterBottom variant='body2' sx={{ whiteSpace: "pre-wrap" }}>
                    {formatVoucherText(claimedVoucher.customClaimText) ?? "Please present this screen to the merchant during your purchase."}
                </Typography>
            </Box>
        </Modal>
    )
}