
import { Marker } from '@react-google-maps/api';
import React from 'react';


export function CustomMarker({ location, setInfoWindow }) {
    const coordinate = {
        lat: location.latitude,
        lng: location.longitude,
    }
    return (
        <>
            <Marker
                icon={new window.google.maps.MarkerImage(
                    "https://cdn-icons-png.flaticon.com/512/2377/2377922.png",
                    null,
                    null,
                    null,
                    new window.google.maps.Size(30, 30),
                )}
                position={coordinate}
                onClick={() => setInfoWindow(location)}
                animation={window.google.maps.Animation.DROP}
            />
        </>
    )
}