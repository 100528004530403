import React, { useEffect, useState } from 'react';
import { InputLabel, Button } from '@mui/material';
import { Controller } from 'react-hook-form';
import Compressor from 'compressorjs';

function ImageUploadController({ renderInput, onChange: ignored, control, defaultValue, name, renderOption, imageURL, setImageURL }) {
    const [displayedImage, setDisplayedImage] = useState(defaultValue);

    const handleImageUpload = async (event) => {
        const file = event.target.files[0];
        if (file !== undefined && file.type.startsWith('image/')) {
            new Compressor(file, {
                quality: 0.6,
                success(result) {
                    const compressedImage = URL.createObjectURL(result);
                    setDisplayedImage(compressedImage);
                    setImageURL(compressedImage);
                },
                error(err) {
                    alert(`ERROR! Please try again! Error: ${err.message}`);
                },
            })
        }
        // after we make the changes necessary, we need to delete the value inside the input element
        // this is bc the onChange handler won't trigger if we upload, enter url, then decide to upload again.
        event.target.value = null;
    };

    return (
        <div>
            <Controller
                render={({ onChange, ...props }) => (
                    <div>
                        <InputLabel>Upload Image (&lt;10MB, 1500x750px)</InputLabel>
                        <Button variant="contained" component="label">
                            Select Image
                            <input hidden type="file" accept="image/*" src={displayedImage} onChange={handleImageUpload} />
                        </Button>
                        <div>
                            <br/>
                            {displayedImage && <img src={displayedImage} alt="URL Image" style={{ maxWidth: "25vw", maxHeight: "25vh" }} />}
                        </div>
                    </div>
                )}
                defaultValue={defaultValue}
                name={name}
                control={control}
            />
        </div>
    );
}

export default ImageUploadController;