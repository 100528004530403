import { Box, Button, Divider, FormControlLabel, MenuItem, Modal, Radio, RadioGroup, Stack, TextField, Typography } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import ImageUploadController from '../../components/form/ImageUploader';
import { VoucherViewPermissions } from '../../constants/voucherTypes';
import useCtx from '../../contexts/PerksContext';
import { modalEditStyle } from '../../theme/modal';
import { GetLocation, IsPostalCode } from '../../utils/location';

const viewPermissions = Object.keys(VoucherViewPermissions).map((key) => ({
    label: key,
    value: VoucherViewPermissions[key],
    type: "viewPermission"
}));

export default function VoucherEditModal({
    editModal,
    handleCloseEditVoucher,
    handleSubmitEdits,
    voucher,
    brands,
    imageURL,
    setImageURL,
    locations,
    setLocations,
}) {
    const { control, handleSubmit } = useForm();
    const { fetchDataWithAuth } = useCtx();

    useEffect(() => {
        const fetch = async () => {
            var resp = await fetchDataWithAuth(`voucher/location/all?voucherId=${voucher.voucherId}`);
            var existingLocations = resp.data;
            setLocations(existingLocations.map((l) => {
                return {
                    postalCode: l.postalCode,
                    address: l.address,
                    longitude: l.longitude,
                    latitude: l.latitude,
                }
            }));
        }
        fetch();
    }, [])

    // ADDRESS -------------------------------------------------------------------------------------

    // add empty postal code / address row
    const handleAddLocation = () => {
        var curr = { postalCode: '', address: '', longitude: 0, latitude: 0 };
        if (locations.length > 0) {
            if (!IsPostalCode(locations[locations.length - 1].postalCode)) {
                alert(`Previous postal code is invalid. Please fix that before adding a new one`);
                return;
            }
        }
        setLocations([...locations, curr]);
    };

    const handleRemoveLocation = (index) => {
        const updatedLocations = [...locations];
        updatedLocations.splice(locations.length - 1, 1);
        setLocations(updatedLocations);
    };

    const handlePostalCodeChange = (postalCode, index) => {
        const updatedLocations = [...locations];
        updatedLocations[index].postalCode = postalCode;
        setLocations(updatedLocations);
    }

    const handleAddressChange = (address, index) => {
        const updatedLocations = [...locations];
        updatedLocations[index].address = address;
       setLocations(updatedLocations);
    }

    const handlePopulateAddresses = async () => {
        const updatedLocations = [...locations];
        for (let i = 0; i < updatedLocations.length; i++) {
            const l = updatedLocations[i];
            const postalCode = l.postalCode;
            if (IsPostalCode(postalCode)) {
                const resp = await GetLocation(postalCode);
                l.address = resp["ADDRESS"];
                l.longitude = resp["LONGITUDE"];
                l.latitude = resp["LATITUDE"];
            } else {
                l.address = "INVALID POSTAL CODE";
                l.longitude = 0;
                l.latitude = 0;
            }
            updatedLocations[i] = l;
        }
        setLocations(updatedLocations);
    }


    // -------------------------------------------------------------------------------------


    return (
        <Modal
            open={editModal}
            onClose={handleCloseEditVoucher}
            sx={{ overflow: 'scroll' }}
        >
            <Box
                noValidate
                autoComplete="off"
                sx={modalEditStyle}
            >
                <Typography
                    fontSize={30}
                    marginBottom={5}
                >
                    Edit Voucher
                </Typography>
                <form onSubmit={handleSubmit(handleSubmitEdits)}>
                    <Stack
                        spacing={2}
                        noValidate
                        autoComplete="off"
                    >
                        <Controller
                            name="brandId"
                            control={control}
                            defaultValue={voucher.brandId}
                            render={({ field }) => <TextField
                                {...field}
                                select
                                label="Brand"
                            >
                                {brands.map((b, idx) => (
                                    <MenuItem key={idx} value={b.brandId}>
                                        {b.name}
                                    </MenuItem>
                                ))}
                            </TextField>}
                        />
                        <Controller
                            name="name"
                            control={control}
                            defaultValue={voucher.name}
                            render={({ field }) => <TextField {...field} label="Name" fullWidth />}
                        />
                        <Controller
                            name="userClaimLimit"
                            control={control}
                            defaultValue={voucher.userClaimLimit}
                            render={({ field }) => <TextField {...field} label="User Claim Limit (0 for unlimited)" fullWidth />}
                        />
                        <Controller
                            name="viewPermission"
                            control={control}
                            defaultValue={voucher.viewPermission}
                            render={({ field }) => <TextField
                                {...field}
                                select
                                label="Viewing Permission"
                            >
                                {viewPermissions.map((permission, idx) => (
                                    <MenuItem key={idx} value={permission.value}>
                                        {permission.label}
                                    </MenuItem>
                                ))}
                            </TextField>}
                        />
                        <Controller
                            name="customClaimText"
                            control={control}
                            defaultValue={voucher.customClaimText}
                            render={({ field }) => <TextField {...field} label="Custom Claim Text (optional)" fullWidth multiline maxRows={5} />}
                        />
                        <ImageUploadController
                            name="imageUrl"
                            control={control}
                            defaultValue={voucher.imageUrl}
                            imageURL={imageURL}
                            setImageURL={setImageURL}
                        />
                        <Controller
                            name="termsAndConditions"
                            control={control}
                            defaultValue={voucher.termsAndConditions}
                            render={({ field }) => <TextField {...field} label="Terms and Conditions" fullWidth multiline maxRows={5} />}
                        />
                        <Controller
                            name="hasExpiry"
                            control={control}
                            defaultValue={voucher.expiresAt ? true : false}
                            render={({ field }) =>
                                <RadioGroup
                                    row
                                    {...field}
                                >
                                    <FormControlLabel value="false" control={<Radio />} label="No Expiry" />
                                    <FormControlLabel value="true" control={<Radio />} label="Has Expiry (please enter date below)" />
                                </RadioGroup>}
                        />
                        <Controller
                            name="expiryDate"
                            control={control}
                            defaultValue={voucher.expiresAt ? dayjs(voucher.expiresAt) : dayjs(dayjs().add(99, 'year'))}
                            label="Expiry Date"
                            render={({ field }) => <DateTimePicker {...field} />}
                        />

                        {locations.map((location, index) => (
                            <Stack
                                key={index}
                                direction="row"
                                spacing={2}
                                alignItems="center"
                            >
                                <Controller
                                    name={`locations[${index}].postalCode`}
                                    control={control}
                                    defaultValue={locations[index].postalCode.toString()}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            label={`Postal Code ${index + 1}`}
                                            sx={{
                                                width: '50%',
                                            }}
                                            value={locations[index].postalCode.toString()}
                                            onChange={(e) => handlePostalCodeChange(e.target.value, index)}
                                        />
                                    )}
                                />
                                <TextField
                                    label={`Address ${index + 1}`}
                                    sx={{
                                        width: '50%',
                                    }}
                                    value={location.address}
                                    onChange={(e) => handleAddressChange(e.target.value, index)}
                                />
                            </Stack>
                        ))}

                        <Stack direction="row" spacing={2}>
                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                onClick={handleAddLocation}
                            >
                                Add Address
                            </Button>
                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                onClick={handleRemoveLocation}
                            >
                                Remove Address
                            </Button>
                            <Button
                                fullWidth
                                variant="contained"
                                color="error"
                                onClick={handlePopulateAddresses}
                            >
                                Populate Addresses
                            </Button>
                        </Stack>

                        <Divider variant="middle" />


                        <Button type="submit" variant="contained" color="primary">
                            Submit
                        </Button>
                    </Stack>
                </form>
            </Box>
        </Modal>
    )
}