export const VoucherMassUploadFields = [
    "BrandId",
    "VoucherName",
    "VoucherCode",
    "DiscountType",
    "UserClaimLimit",
    "IsMultiClaim",
    "VoucherClaimLimit",
    "ImageUrl",
    "TermsAndConditions",
    "ExpiryDate",
    "ViewPermission",
];

export const VoucherDiscountTypes = [
    {name: "Absolute e.g. $20 off", id: 1},
    {name: "Percentage e.g. 20% off", id: 2},
    {name: "Reward e.g. Buy 1 get 1 free", id: 3},
];

export const VoucherStatus = {
    Inactive: 0,
    Active: 1,
};

export const VoucherViewPermissions = {
    All: 0,
    StaffOnly: 1,
    CreatorOnly: 2,
}