import {
    Card,
    CardContent,
    Typography
} from '@mui/material';
import { useCallback } from 'react';
import useCtx from '../../contexts/PerksContext';
import Label from '../../components/label/Label';

export const AccountDetails = () => {
    const { user } = useCtx();
    const originalDate = user.birthday;
    const dateObject = new Date(originalDate);
    const formattedBirthday = dateObject.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    });
    user.birthday = formattedBirthday;

    const handleSubmit = useCallback(
        (event) => {
            event.preventDefault();
        },
        []
    );

    return (
            <Card>
                <CardContent>
                    <Typography variant="body1" gutterBottom>
                        <strong>Email:</strong> {user.email}
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        <strong>Company:</strong> {user.company}
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        <strong>Role:</strong> {user.permissionType}
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        <strong>Account Status:</strong> <Label color={'success'}>{user.accountStatus === 1 ? 'Active' : 'Inactive'}</Label>
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        <strong>Voucher Quota:</strong> {user.voucherQuota}
                    </Typography>
                </CardContent>
            </Card>
    );
};