import {
  Container,
  Stack,
  Typography
} from '@mui/material';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import DownloadCsvButton from '../components/download-csv/download-csv';
import useCtx from '../contexts/PerksContext';
import { VoucherTransactionsTable } from '../sections/vouchers/VoucherTransactionsTable';


export function TransactionsPage() {

  const [isDownloading, setIsDownloading] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const { fetchPagedData } = useCtx();


  const handleDownload = async () => {
    setIsDownloading(true);
    var page = 1;
    var rows = 50;
    var totalPages = 1;
    var txns = [];
    try {
      while (page <= totalPages) {
        const resp = await fetchPagedData(`vouchertransaction/all`, page, rows);
        const txn = resp.data;
        txns = [...txns, ...txn];
        totalPages = resp.totalPages;
        page++;
      }

    } catch (err) {
      console.error(`Failed to get transactions: ${err.message}`);
      return;
    }
    setTimeout(() => {
      setTransactions(txns);
      setIsDownloading(false);
    }, 1000);
  }


  //---------------------------------------------------------------

  return (
    <>
      <Helmet>
        <title> HCN Perks </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Transactions
          </Typography>
          <DownloadCsvButton
            data={transactions}
            fileName={"transactions.csv"}
            handleDownload={handleDownload}
            isDownloading={isDownloading}
            buttonText={"Download Transactions"}
          />
        </Stack>

        <VoucherTransactionsTable />
      </Container>
    </>
  )
}