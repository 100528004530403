import { Box } from '@mui/material';
import React from 'react';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import 'react-toastify/dist/ReactToastify.css';
import { mapCarouselResponsive } from '../../theme/maps';


// TODO: figure out why this is re-rendering after scrolling through 3 slides
export function MapCarousel({ slides, setSelectedVoucher, setInfoWindow, setCenter, isTracking, userLocation }) {
    return (
        <Box mt={1}>
            <Carousel
                afterChange={(previousSlide, { currentSlide, onMove }) => {
                    setInfoWindow(null); // close any infowindow when carousel slides
                    if (currentSlide === 0) {
                        setSelectedVoucher(null);
                        setCenter(userLocation);
                    } else {
                        var location = slides[currentSlide - 1];
                        setSelectedVoucher(location.voucherId)
                        if (isTracking) {
                            setCenter({
                                lat: location.latitude,
                                lng: location.longitude
                            })
                        }
                    }
                }}
                additionalTransfrom={0}
                centerMode
                draggable={true}
                minimumTouchDrag={80}
                arrows
                containerClass="carousel-container"
                responsive={mapCarouselResponsive}
                transitionDuration={100}
                slidesToSlide={1}
                swipeable={true}
                keyBoardControl
            >
                <div style={{
                    position: 'relative',
                    display: 'inline-block',
                    padding: '10px',
                    height: '100%'
                }}>

                    <img
                        src={`/assets/HCN_logo.png`}
                        style={{
                            minWidth: '100%',
                            minHeight: '20vh',
                            display: 'block',
                            objectFit: 'cover',
                            borderRadius: '10px',
                        }}
                    />
                </div>
                {slides.map((slide, idx) => (
                    <CarouselSlide slide={slide} key={idx} />
                ))}
            </Carousel>
        </Box>
    )
}

function CarouselSlide({ slide }) {
    return (
        <div style={{
            position: 'relative',
            display: 'inline-block',
            padding: '10px',
            height: '100%'
        }}>
            <img
                src={!slide.imageUrl || slide.imageUrl === "" ? '/assets/HCN_logo.png' : slide.imageUrl}
                style={{
                    minWidth: '100%',
                    minHeight: '20vh',
                    display: 'block',
                    objectFit: 'cover',
                    borderRadius: '10px',
                }}
            />
            <div style={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                right: 0,
                height: '50%',
                background: 'rgba(255, 255, 255, 0.8)',
                color: 'black',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '10px',
                boxSizing: 'border-box'
            }}>
                <span style={{
                    fontWeight: 'bold',
                    fontSize: 'larger'
                }}>
                    {slide.brandName.length <= 15 ? slide.brandName : `${slide.brandName.substring(0, 13)}...`}
                </span>
                <span>
                    {slide.voucherName.length <= 15 ? slide.voucherName : `${slide.voucherName.substring(0, 15)}...`}
                </span>
            </div>
        </div>

    );
};
