import {
    Autocomplete,
    Box,
    Button,
    Container,
    Grid,
    Input,
    InputLabel,
    Modal,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    TextField,
    Typography
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import Papa from 'papaparse';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import getImageBase64 from '../../components/form/ConvertImage';
import ImageUploader from '../../components/form/ImageUploader';
import { VoucherDiscountTypes, VoucherMassUploadFields, VoucherViewPermissions } from '../../constants/voucherTypes';
import useCtx from '../../contexts/PerksContext';
import { modalEditStyle } from '../../theme/modal';


export default function VoucherCreationPage() {
    const { control, handleSubmit, reset } = useForm();
    const { postDataWithAuth, fetchDataWithAuth } = useCtx();
    const [imageURL, setImageURL] = useState('');
    const [brands, setBrands] = useState([]);

    useEffect(() => {
        const fetch = async () => {
            try {
                const url = "brand/all";
                const resp = await fetchDataWithAuth(url)
                setBrands(resp.data);
            } catch (error) {
                alert(`Failed to fetch brands: ${JSON.stringify(error.response)}`);
            }
        }
        fetch();
    }, []);

    const handleSubmitVoucher = async (data) => {
        try {
            var voucher = {};
            voucher.status = 1;
            voucher.name = data.name;
            voucher.imageUrl = await getImageBase64(imageURL);
            voucher.brandId = data.brandId.value;
            voucher.discountType = data.discountType.value;
            voucher.viewPermission = data.viewPermission.value;
            voucher.expiresAt = new Date(new Date(data.expiresAt).getTime() - new Date().getTimezoneOffset() * 60000);
            voucher.voucherCodes = [{
                voucherCode: data.voucherCode,
                isMultiClaim: true,
                claimLimit: data.voucherClaimLimit,
            }];
            await postDataWithAuth(`voucher/mass-upload`, {
                vouchers: [voucher]
            });
            reset();
            alert(`Successfully created Voucher`);
        } catch (error) {
            alert(`Failed to create Voucher ${error}`);
        }
    }

    const parsedBrands = brands.map((b) => {
        return {
            label: b.name,
            value: b.brandId,
            type: "brandID"
        };
    });

    const discountTypes = VoucherDiscountTypes.map((t) => {
        return {
            label: t.name,
            value: t.id,
            type: "discountType"
        }
    })

    const viewPermissions = Object.keys(VoucherViewPermissions).map((key) => ({
        label: key,
        value: VoucherViewPermissions[key],
        type: "viewPermission"
    }));

    // File upload --------------------------------------------------------

    const [file, setFile] = useState(null);
    const handleFileUpload = (event) => {
        const selectedFile = event.target.files[0];
        setFile(selectedFile);
    }
    const isValidUpload = (data, voucherFields) => {
        // check number of rows
        if (data.length > 100) return false;

        // check headers
        const headers = data[0];
        if (headers.length !== voucherFields.length) return false;
        for (let i = 0; i < headers.length; i++) {
            if (headers[i].trim() != voucherFields[i]) {
                return false;
            }
        }
        return true;
    }

    const hasDuplicateCode = (codes) => {
        const seen = new Set();
        for (const value of codes) {
            if (seen.has(value)) {
                return true;
            }
            seen.add(value);
        }
        return false;
    }

    const constructMassUploadBody = (data) => {
        data.shift(); // remove headers
        var m = {}; // unique key = brandid_name
        data.forEach((d) => {
            const brandId = d[0];
            const name = d[1];
            const voucherCode = d[2];
            const discountType = d[3];
            const userClaimLimit = d[4];
            const isMultiClaim = d[5] === "TRUE" || d[5] === 1 ? true : false;
            const voucherClaimLimit = d[6];
            const imageUrl = d[7];
            const terms = d[8];
            const expiryDateUnix = d[9];
            const viewPermission = d[10];
            const uniqueKey = `${brandId}_${name}`;
            const voucherCodeObj = {
                voucherCode: voucherCode,
                isMultiClaim: isMultiClaim,
                claimLimit: voucherClaimLimit,
            };
            if (m[uniqueKey]) {
                m[uniqueKey].voucherCodes.push(voucherCodeObj);
            } else {
                m[uniqueKey] = {
                    brandId: brandId,
                    name: name,
                    discountType: discountType,
                    userClaimLimit: userClaimLimit,
                    voucherClaimLimit: voucherClaimLimit,
                    status: 1,
                    imageUrl: imageUrl,
                    termsAndConditions: terms,
                    expiresAt: new Date(expiryDateUnix * 1000).toISOString(),
                    voucherCodes: [voucherCodeObj],
                    viewPermission: viewPermission,
                };
            }
        });
        var resp = {
            vouchers: [],
        }
        for (const key in m) {
            if (hasDuplicateCode(m[key].voucherCodes)) {
                alert(`Duplicate voucher code found for ${m[key]}`)
                return {};
            }
            resp.vouchers.push(m[key]);
        }
        return resp;
    }

    const handleMassUpload = () => {
        if (file) {
            const reader = new FileReader();
            reader.onload = (event) => {
                const fileContent = event.target.result;
                Papa.parse(fileContent, {
                    complete: async (result) => {
                        if (!isValidUpload(result.data, VoucherMassUploadFields)) {
                            alert("Invalid csv format. Either your headers are incorrect or there are more than 100 rows");
                            return;
                        }
                        const body = constructMassUploadBody(result.data);
                        const url = `voucher/mass-upload`;
                        try {
                            const resp = await postDataWithAuth(url, body);
                            alert(`Successfully created vouchers with IDs: ${resp.voucherIds}`);
                        } catch (e) {
                            alert(`Mass upload failed: ${e}`);
                        }
                    },
                });
            };
            reader.readAsText(file)
        } else {
            alert("Please choose a file to upload");
        }

    }

    // Modal ---------------------------------------------------------------

    const [modal, setModal] = useState(false);
    const [modalDetails, setModalDetails] = useState({
        title: "",
        list: [], // [{name, id}, ...]
    });
    const handleOpenModal = (listType) => {
        switch (listType) {
            case "brandIds": {
                const obj = {
                    title: "Brands IDs",
                    list: brands.sort((a, b) => {
                        return (
                            a.name.localeCompare(b.name)
                        );
                    }).map((b) => {
                        return {
                            name: b.name,
                            id: b.brandId
                        }
                    }),
                };
                setModalDetails(obj);
                break;
            }
            case "discountTypes": {
                const obj = {
                    title: "Discount Types",
                    list: VoucherDiscountTypes,
                };
                setModalDetails(obj);
                break;
            }
            case "viewPermissions": {
                const obj = {
                    title: "View Permissions",
                    list: Object.keys(VoucherViewPermissions).map((key) => ({
                        name: key,
                        id: VoucherViewPermissions[key],
                    })),
                };
                setModalDetails(obj);
                break;
            }
            default: break;
        }
        setModal(true);
    };
    const handleCloseModal = () => setModal(false);


    // ---------------------------------------------------------------------------------

    return (
        <Container>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={6}>
                    <Typography variant="h4" align="center" gutterBottom>
                        Add Single Voucher
                    </Typography>
                    <Typography variant="h6" align="center" gutterBottom>
                        (for multiclaim / single-code vouchers only)
                    </Typography>
                    <form onSubmit={handleSubmit(handleSubmitVoucher)}>
                        <Grid container spacing={2} sx={{ mt: 5 }} justifyContent={'center'} >
                            <Grid item xs={8}>
                                <Controller
                                    name="name"
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => <TextField {...field} label="Name" fullWidth />}
                                />
                            </Grid>
                            <Grid item xs={8}>
                                <Controller
                                    rules={{ required: true }}
                                    name="brandId"
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <Autocomplete
                                            onChange={(event, item) => {
                                                onChange(item);
                                            }}
                                            value={value || null}
                                            options={parsedBrands}
                                            getOptionLabel={(option) => option.label}
                                            isOptionEqualToValue={(option, value) => {
                                                return option.label === value.label;
                                            }}
                                            renderInput={(params) => <TextField {...params} label="Brands" margin="normal" />}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={8}>
                                <Controller
                                    rules={{ required: true }}
                                    name="viewPermission"
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <Autocomplete
                                            onChange={(event, item) => {
                                                onChange(item);
                                            }}
                                            value={value || null}
                                            options={viewPermissions}
                                            getOptionLabel={(option) => option.label}
                                            isOptionEqualToValue={(option, value) => {
                                                return option.label === value.label;
                                            }}
                                            renderInput={(params) => <TextField {...params} label="Viewing Permission" margin="normal" />}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={8}>
                                <Controller
                                    name="voucherCode"
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => <TextField {...field} label="Voucher Code" fullWidth />}
                                />
                            </Grid>
                            <Grid item xs={8}>
                                <Controller
                                    rules={{ required: true }}
                                    name="discountType"
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <Autocomplete
                                            onChange={(event, item) => {
                                                onChange(item);
                                            }}
                                            value={value || null}
                                            options={discountTypes}
                                            getOptionLabel={(option) => option.label}
                                            isOptionEqualToValue={(option, value) => {
                                                return option.label === value.label;
                                            }}
                                            renderInput={(params) => <TextField {...params} label="Discount Type" margin="normal" />}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={8}>
                                <Controller
                                    name="userClaimLimit"
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => <TextField {...field} label="User Claim Limit" fullWidth />}
                                />
                            </Grid>
                            <Grid item xs={8}>
                                <Controller
                                    name="voucherClaimLimit"
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => <TextField {...field} label="Voucher Claim Limit" fullWidth />}
                                />
                            </Grid>

                            <Grid item xs={8}>
                                <Controller
                                    name="termsAndConditions"
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => <TextField {...field} label="T&Cs" fullWidth />}
                                />
                            </Grid>
                            <Grid item xs={8}>
                                <Controller
                                    name="expiresAt"
                                    control={control}
                                    defaultValue={dayjs().add(1, 'week')}
                                    render={({ field }) => <DateTimePicker {...field} label="Expiry Date" fullWidth />}
                                />
                            </Grid>
                            <Grid item xs={8}>
                                <ImageUploader
                                    name="imageUrl"
                                    control={control}
                                    defaultValue={null}
                                    imageURL={imageURL}
                                    setImageURL={setImageURL}
                                />
                            </Grid>
                            <Grid item xs={8}>
                            </Grid>
                            <Grid item xs={8}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                >
                                    Save
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Grid>



                <Grid item xs={12} sm={6} md={6}>
                    <Typography variant="h4" align="center" gutterBottom>
                        Mass Upload Vouchers
                    </Typography>
                    <Grid container spacing={2} sx={{ mt: 5 }} justifyContent={'center'} >
                        <Grid item xs={8}>
                            <Typography>
                                CSV format:<br />
                            </Typography>
                            <Box
                                component="div"
                                sx={{
                                    whiteSpace: 'normal',
                                    my: 2,
                                    p: 1,
                                    bgcolor: 'grey.300',
                                    color: 'grey.800',
                                    border: '1px solid',
                                    borderColor: 'grey.300',
                                    borderRadius: 2,
                                    fontSize: '0.875rem',
                                    fontWeight: '700',
                                }}>
                                <b>{VoucherMassUploadFields.join(", ")}</b>
                                <br />
                                <br />
                                <Typography
                                    marginBottom={1}
                                    sx={{
                                        fontSize: '0.600rem',
                                    }}>* UserClaimLimit refers to number of times a user can claim a particular voucher</Typography>
                                <Typography
                                    marginBottom={1}
                                    sx={{
                                        fontSize: '0.600rem',
                                    }}>* VoucherClaimLimit refers to number of times a voucher can be claimed. Only applies to multi-claim vouchers</Typography>
                                <Typography
                                    marginBottom={1}
                                    sx={{
                                        fontSize: '0.600rem',
                                    }}>* ExpiryDate should be a UNIX timestamp</Typography>
                                <Typography
                                    sx={{
                                        fontSize: '0.600rem',
                                    }}>* ViewPermission rules which groups can view a voucher</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={8} marginBottom={4}>
                            <Typography>
                                Additional info:
                            </Typography>
                            <Button onClick={() => handleOpenModal("brandIds")}>List of Brand IDs</Button><br />
                            <Button onClick={() => handleOpenModal("discountTypes")}>List of Discount Types</Button><br />
                            <Button onClick={() => handleOpenModal("viewPermissions")}>List of View Permissions</Button><br />
                        </Grid>
                        <Grid item xs={8}>
                            <InputLabel>Upload Vouchers (.csv)</InputLabel>
                            <Input
                                name='voucherCodes'
                                fullWidth
                                type="file"
                                inputProps={{ accept: '.csv' }} // Specify accepted file types
                                onChange={handleFileUpload}
                            />
                        </Grid>
                        <Grid item xs={8}>
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                onClick={handleMassUpload}
                            >
                                Upload
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Modal
                open={modal}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalEditStyle}>
                    <Typography variant="h3" component="h2" gutterBottom>
                        {modalDetails.title}
                    </Typography>
                    <div style={{ height: '300px', overflow: 'auto' }}>
                        <TableContainer>
                            <Table>
                                <TableBody>
                                    {modalDetails.list.map((item, idx) => (
                                        <TableRow key={idx}>
                                            <TableCell>
                                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                    <span>{item.name}</span>
                                                    <span>{item.id}</span>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    ))}

                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </Box>
            </Modal>
        </Container>
    );
}