import { Box, CardMedia, Container, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { GoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { Toast } from '../components/toast';
import useResponsive from '../hooks/useResponsive';
import { getLocalStorageWithExpiry, setLocalStorageWithExpiry } from '../utils/localStorage';
import { getBaseUrl } from '../utils/url';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function LoginPage() {
  const mdUp = useResponsive('up', 'md');
  const navigate = useNavigate();
  const authURL = `${getBaseUrl()}/authenticate`;
  const [toast, setToast] = useState(false);

  useEffect(() => {
    const jwt = getLocalStorageWithExpiry("jwt")
    if (jwt) {
      navigate('/dashboard/vouchers');
    }
  }, [navigate])


  // TOAST ------------------------------------------------------------------------------

  const handleCloseToast = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setToast(false);
  }

  // LOGIN ------------------------------------------------------------------------------


  const handleLogin = async (credentialResponse) => {
    try {
      const token = await axios.post(authURL, {
        idToken: credentialResponse.credential,
      });
      setLocalStorageWithExpiry('jwt', token.data.token, 720);
      setLocalStorageWithExpiry('userId', token.data.userId, 720);
      navigate('/dashboard/vouchers');
      window.location.reload();
    } catch (e) {
      setToast(true);
    }
  }

  //------------------------------------------------------------------------------

  return (
    <>
      <Helmet>
        <title> Login | HCN Perks </title>
      </Helmet>

      <StyledRoot>

        {mdUp && (
          <StyledSection>
            <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
              Hi, welcome back!
            </Typography>
            <img src="/assets/illustrations/illustration_login.png" alt="login" />
          </StyledSection>
        )}

        <Container maxWidth="sm">
          <StyledContent>
            <Box
              component="img"
              sx={{
                height: 200,
                width: 200,
                marginLeft: "auto",
                marginRight: "auto",
              }}
              alt="The house from the offer."
              src="/assets/HCN.png"
            />

            <Typography variant="h4" gutterBottom>
              Sign in to HCN Perks
            </Typography>

            <Typography variant="body2" sx={{ mb: 5 }}>
              If you can't log in through Google, please contact a HCN staff
            </Typography>

            <Stack direction="row" spacing={2}>
              <GoogleLogin
                onSuccess={handleLogin}
                onError={(e) => {
                  console.error(`Login Failed: ${e}`);
                }}
              />
            </Stack>
          </StyledContent>
        </Container>
      </StyledRoot>

      <Toast
        toast={toast}
        handleCloseToast={handleCloseToast}
        message={"Sorry, seems you're not allowed in!"}
      />
    </>
  );
}
