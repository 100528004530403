import {
    Avatar,
    Card
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import useCtx from '../../contexts/PerksContext';
import { fDateTime } from '../../utils/formatTime';

// ----------------------------------------------------------------------


const columns = [
    {
        field: 'imageUrl', headerName: '', width: 50, renderCell: (params) => (
            <>
                <Avatar src={params.value} />
            </>
        ),
    },
    { field: 'voucherName', headerName: 'Voucher Name', width: 200 },
    { field: 'voucherCode', headerName: 'Voucher Code', width: 150 },
    { field: 'claimedAt', headerName: 'Claimed At', width: 170 },
];

// ----------------------------------------------------------------------


export const AccountVoucherCodes = () => {
    const { userId, fetchDataWithAuth } = useCtx();
    const [claimedVouchers, setClaimedVouchers] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const claimUrl = `voucher/claimed/${userId}`;
                const vouchersUrl = `voucher/all`;
                var resp = await fetchDataWithAuth(vouchersUrl);
                const vouchers = resp;
                const m = {};
                vouchers.forEach(v => {
                    m[v.voucherId] = v;
                });
                resp = await fetchDataWithAuth(claimUrl);
                setClaimedVouchers(resp.vouchers.map((v, idx) => {
                    const imageUrl = m[v.voucherId].imageUrl;
                    return {
                        'id': idx,
                        'imageUrl': imageUrl ? imageUrl : "/assets/HCN_logo.png",
                        'voucherName': m[v.voucherId].name,
                        'voucherCode': v.voucherCode,
                        'claimedAt': fDateTime(v.claimedAt),
                    }
                }));
                
            } catch(e) {
                console.error("Failed to get claimed vouchers: " + e);
            }
        }
        fetchData();
    }, [])
    return (
        <Card>
            <DataGrid
                rows={claimedVouchers}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5, 10, 20]}
                autoHeight
                disableColumnMenu
            />
        </Card>
    );

}
