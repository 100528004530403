// @mui
import {
    Box,
    Divider,
    Drawer,
    FormControlLabel,
    FormGroup,
    IconButton,
    Stack,
    Switch,
    Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';

import Iconify from '../../components/iconify/Iconify';
import Scrollbar from '../../components/scrollbar';

//=================================================================================================

const CustomSwitch = styled(Switch)(({ theme }) => ({
    padding: 8,
    '& .MuiSwitch-track': {
        borderRadius: 22 / 2,
        '&:before, &:after': {
            content: '""',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            width: 16,
            height: 16,
        },
        '&:before': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                theme.palette.getContrastText(theme.palette.primary.main),
            )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
            left: 12,
        },
        '&:after': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                theme.palette.getContrastText(theme.palette.primary.main),
            )}" d="M19,13H5V11H19V13Z" /></svg>')`,
            right: 12,
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: 'none',
        width: 16,
        height: 16,
        margin: 2,
    },
}));

//=================================================================================================

export function MapDrawer({
    open,
    onClose,
    showAllLocations,
    setShowAllLocations,
    isTracking,
    setIsTracking,
}) {
    return (
        <>
            <Drawer
                anchor="right"
                open={open}
                onClose={onClose}
                PaperProps={{
                    sx: { width: 280, border: 'none', overflow: 'hidden' },
                }}
            >
                <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ px: 1, py: 2 }}>
                    <Typography variant="subtitle1" sx={{ ml: 1 }}>
                        Settings
                    </Typography>
                    <IconButton onClick={onClose}>
                        <Iconify icon="eva:close-fill" />
                    </IconButton>
                </Stack>

                <Divider />

                <Scrollbar>
                    <Box sx={{ pl: 3, pt: 2 }}>
                        <FormGroup>
                            <FormControlLabel
                                control={<CustomSwitch />}
                                checked={showAllLocations}
                                onChange={() => setShowAllLocations(!showAllLocations)}
                                label="Show All Locations" />
                            <FormControlLabel
                                control={<CustomSwitch />}
                                checked={isTracking}
                                onChange={() => setIsTracking(!isTracking)}
                                label="Swing To Closest Location"
                            />
                        </FormGroup>
                    </Box>
                </Scrollbar>
            </Drawer>
        </>
    )
}

