import {
    Box,
    Modal,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography
} from '@mui/material';
import { modalEditStyle } from '../../theme/modal';


export default function VoucherCodesModal({ open, modal, handleCloseModal, codes }) {

    return (
        <>
            {Object.keys(open).length > 0 && (
                <Modal
                    open={modal}
                    onClose={handleCloseModal}
                >
                    <Box
                        noValidate
                        autoComplete="off"
                        sx={modalEditStyle}
                    >
                        <Typography
                            fontSize={30}
                            marginBottom={5}
                        >
                            Voucher Codes
                        </Typography>
                        <Typography>
                            Total count: {codes.length}
                        </Typography>
                        <div style={{ height: '400px', overflow: 'auto' }}>
                            <TableContainer>
                                <Table>
                                    <TableBody>
                                        {codes.map((code, idx) => (
                                            <TableRow key={idx}>
                                                <TableCell>
                                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                        <span>{idx+1}</span>
                                                        <span>{code.voucherCode}</span>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        ))}

                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </Box>
                </Modal>
            )}
        </>
    )
}