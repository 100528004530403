import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
// @mui
import {
  Avatar,
  Button,
  Card,
  Checkbox,
  Container,
  IconButton,
  MenuItem,
  Popover,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';
import { Link } from 'react-router-dom';
import Iconify from '../../components/iconify';
import Label from '../../components/label';
import { ListHead, ListToolbar, NotFound } from '../../components/table';
import { BrandStatusTypes } from '../../constants/brandTypes';
import useCtx from '../../contexts/PerksContext';
import { BrandEditModal } from '../../sections/brand';
import { fDateTime } from '../../utils/formatTime';
import { applySortFilter, getComparator } from '../../utils/sortFilter';

// HEADERS ----------------------------------------------------------------------


const headers = [
  { id: 'name', label: 'Name'},
  { id: 'description', label: 'Description'},
  { id: 'status', label: 'Status' },
  { id: 'createdAt', label: 'Created At' },
  { id: 'updatedAt', label: 'Updated At' },
  { id: '' }
]

// ----------------------------------------------------------------------

export default function BrandPage() {
  const [open, setOpen] = useState({});
  const [editModal, setEditModal] = useState(false);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filteredBrands, setFilteredBrands] = useState([]);
  const [emptyRows, setEmptyRows] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalBrandCount, setTotalBrandCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [sort, setSort] = useState(null);
  const [brands, setBrands] = useState([]);
  const { fetchDataWithAuth, putDataWithAuth, fetchPagedData } = useCtx();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const params = [
          { name: 'searchTerm', value: searchTerm }
        ];
        if (sort !== null) {
          params.push(
            { name: 'sortBy', value: sort.column },
            { name: 'sortOrder', value: sort.order }
          );
        }
        const b = await fetchPagedData(`brand/all`, 
        page + 1, 
        rowsPerPage,
        ...params
        );
        const brands = b.data.map((brand, idx) => {
          return {
            ...brand,
            id: idx,
          }
        })
        setBrands(brands);
        const newFilter = applySortFilter(brands, getComparator(order, orderBy), searchTerm);
        setFilteredBrands(newFilter);
        setEmptyRows(page > 0 ? Math.max(0, (1 + page) * rowsPerPage - totalBrandCount) : 0);
        setTotalBrandCount(b.totalRecords);
      } catch (e) {
        console.error("Failed to get brands");
      }
    }
    fetchData();
  }, [open, editModal, sort, searchTerm, page, rowsPerPage])


  // TABLE ---------------------------------------------------------------


  // stores both HTML target (so it knows where to place the popover)
  // and the selected user information
  const handleOpenMenu = (event, brand) => {
    setOpen({
      target: event.currentTarget,
      brand: brand,
    });
  };

  const handleCloseMenu = () => {
    setOpen({});
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = filteredBrands.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setSearchTerm(event.target.value);
  };


  const isNotFound = !filteredBrands.length && !!searchTerm;


  // EDIT AND DELETE ---------------------------------------------------------------

  const handleBrandStatusChange = async (status) => {
    try {
      const url = `brand/edit/${open.brand.brandId}`;
      await putDataWithAuth(url, {
        status: BrandStatusTypes.find(s => s.name === status).id,
      });
      handleCloseMenu();
      status === "inactive" ? alert("Successfully disabled brand") :
        alert("Successfully enabled brand");
    } catch (error) {
      alert(`Action failed: ${error}`)
    }
  }

  const handleOpenEditModal = () => {
    setEditModal(true);
  }

  const handleCloseEditModal = () => {
    setEditModal(false);
    handleCloseMenu();
  }

  const handleSubmitEdit = async (data) => {
    try {
      const url = `brand/edit/${open.brand.brandId}`
      await putDataWithAuth(url, data);
      alert(`Successfully updated brand!`);
      handleCloseEditModal();
    } catch (e) {
      alert(`Failed to update user: ${e}`);
    }
  }


  // RENDER ---------------------------------------------------------------



  return (
    <>
      <Helmet>
        <title> HCN Perks </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Brands
          </Typography>
          <Button
            component={Link}
            to={`/dashboard/brands/new`}
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}>
            New Brand
          </Button>
        </Stack>

        <Card>
          <ListToolbar numSelected={selected.length} filterName={searchTerm} tableName={"brand"} onFilterName={handleFilterByName} placeholder={"Search brand..."} />

          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <ListHead
                order={order}
                orderBy={orderBy}
                headLabel={headers}
                rowCount={totalBrandCount}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
              />
              <TableBody>
                {filteredBrands.map((row) => {
                  const { id, name, description, imageUrl, status, createdAt, updatedAt } = row;
                  const selectedBrand = selected.indexOf(name) !== -1;

                  return (
                    <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedBrand}>
                      <TableCell padding="checkbox">
                        <Checkbox checked={selectedBrand} onChange={(event) => handleClick(event, name)} />
                      </TableCell>

                      <TableCell component="th" scope="row" padding="none">
                        <Stack direction="row" alignItems="center" spacing={2}>
                          <Avatar alt={name} src={imageUrl} />
                          <Typography variant="subtitle2" noWrap>
                            {name}
                          </Typography>
                        </Stack>
                      </TableCell>
                      <TableCell align="left">{description ?? "-"}</TableCell>
                      <TableCell align="left">
                        {status === 1 ? (
                          <Label color={'success'}>Active</Label>
                        ) : (
                          <Label color={'error'}>Disabled</Label>
                        )}
                      </TableCell>
                      <TableCell align="left">{fDateTime(createdAt)}</TableCell>
                      <TableCell align="left">{fDateTime(updatedAt)}</TableCell>

                      <TableCell align="right">
                        <IconButton size="large" color="inherit" onClick={(event) => handleOpenMenu(event, row)}>
                          <Iconify icon={'eva:more-vertical-fill'} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>

              {isNotFound && (
                <NotFound filterName={searchTerm} />
              )}
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={totalBrandCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      <Popover
        open={Boolean(open.target)}
        anchorEl={open.target}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem onClick={handleOpenEditModal}>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem>

        {open.brand && open.brand.status === 0 ? (
          <MenuItem onClick={() => handleBrandStatusChange("active")}>
            <Iconify icon={'eva:checkmark-circle-fill'} sx={{ mr: 2 }} />
            Enable
          </MenuItem>
        ) : (
          <MenuItem sx={{ color: 'error.main' }} onClick={() => handleBrandStatusChange("inactive")}>
            <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
            Delete
          </MenuItem>
        )}

      </Popover>

      {Object.keys(open).length > 0 && (
        <BrandEditModal
          open={editModal}
          handleClose={handleCloseEditModal}
          handleSubmitEdit={handleSubmitEdit}
          data={open} />
      )}

    </>
  );
}
