import { Avatar, Box, Button, Stack, Typography } from '@mui/material';
import { InfoWindow } from '@react-google-maps/api';
import React from 'react';
import { Link } from 'react-router-dom';

export function CustomInfoWindow({ location, setInfoWindow }) {
    const coordinate = {
        lat: location.latitude,
        lng: location.longitude,
    }
    return location && (
        <InfoWindow position={coordinate} onCloseClick={() => setInfoWindow(null)}>
            <Box p={2} maxWidth={300}>
                <Stack direction="row" alignItems="center" spacing={1} marginBottom={1} maxWidth={300}>
                    <Typography variant="h4">
                        <b>{location.brandName}</b>
                    </Typography>
                    <Avatar src={location.imageUrl} sx={{ height: 50, width: 50 }} />
                </Stack>
                <Typography variant="subtitle2">{location.voucherName}</Typography>
                <br />
                <Typography variant="body2" gutterBottom><i>{location.address}</i></Typography>

                <Button
                    variant="outlined"
                    color="primary"
                    component={Link}
                    to={`/dashboard/vouchers/${location.voucherId}`}
                >
                    View Voucher
                </Button>
            </Box>
        </InfoWindow>
    )
}