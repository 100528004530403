import axios from 'axios';
import { useEffect } from 'react';
import { Navigate, useNavigate, useRoutes } from 'react-router-dom';
import { DashboardLayout } from './layouts/DashboardLayout';
import { SimpleLayout } from './layouts/SimpleLayout';
import BrandCreationPage from './pages/Brand/BrandCreationPage';
import BrandPage from './pages/Brand/BrandPage';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import ProfilePage from './pages/ProfilePage';
import { TransactionsPage } from './pages/TransactionsPage';
import UserCreationPage from './pages/User/UserCreationPage';
import UserPage from './pages/User/UserPage';
import VoucherCreationPage from './pages/Voucher/VoucherCreationPage';
import VouchersPage from './pages/Voucher/VouchersPage';
import VoucherDetails from './sections/vouchers/VoucherDetails';
import { getLocalStorageWithExpiry } from './utils/localStorage';
import { getBaseUrl } from './utils/url';
import { NearMe } from './pages/NearMe/NearMePage';
import { ManageVouchersPage } from './pages/ManageVouchersPage';

// ----------------------------------------------------------------------

const AuthGuard = ({ children }) => {
  const navigate = useNavigate();
  useEffect(() => {
    const jwt = getLocalStorageWithExpiry("jwt")
    const fetch = async () => {
      if (!jwt) {
        navigate("/");
        return;
      }

      try {
        await axios.get(`${getBaseUrl()}/authenticate/ping`, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        });
      } catch (error) {
        if (error.response.status === 401) {
          localStorage.clear();
          navigate("/");
        }
      }
    }
    fetch();
  }, [navigate]);
  
  return children;
};

// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      path: '/',
      element: <LoginPage />,
      index: true,  
    },
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/vouchers" />, index: true },
        { path: 'profile', element: <AuthGuard><ProfilePage /></AuthGuard> },
        { path: 'users', element: <AuthGuard><UserPage /></AuthGuard> },
        { path: 'users/new', element: <AuthGuard><UserCreationPage /></AuthGuard> },
        { path: 'vouchers', element: <AuthGuard><VouchersPage /></AuthGuard> },
        { path: 'vouchers/:voucherID', element: <AuthGuard><VoucherDetails /></AuthGuard> },
        { path: 'transactions/new-voucher', element: <AuthGuard><VoucherCreationPage /></AuthGuard> },
        { path: 'transactions', element: <AuthGuard><TransactionsPage /></AuthGuard> },
        { path: 'manage-vouchers', element: <AuthGuard><ManageVouchersPage /></AuthGuard> },
        { path: 'brands', element: <AuthGuard><BrandPage /></AuthGuard> },
        { path: 'brands/new', element: <AuthGuard><BrandCreationPage /></AuthGuard> },
        { path: 'near-me', element: <AuthGuard><NearMe /></AuthGuard> },

      ],
    },
    {
      element: <SimpleLayout />,
      children: [
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
