import { Button, Card, CardContent, CardMedia, Chip, Divider, Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ConfirmationDialog from '../../components/confirmation-dialog/confirmation-dialog';
import useCtx from '../../contexts/PerksContext';
import { formatVoucherText } from '../../utils/formatStrings';
import { fDateTime } from '../../utils/formatTime';
import VoucherClaimModal from './VoucherClaimModal';

// ----------------------------------------------------------------------

export const VoucherDetails = () => {
  const { voucherID } = useParams();
  const { userId, fetchDataWithAuth, postDataWithAuth } = useCtx();
  const [voucher, setVoucher] = useState([]);
  const [claimedVoucher, setClaimedVoucher] = useState({});
  const [brand, setBrand] = useState({}); // refers to the brand to which this voucher belongs
  const [isLoading, setIsLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const v = await fetchDataWithAuth(`voucher/${voucherID}`);
        setVoucher(v);
        setBrand(await fetchDataWithAuth(`brand/${v.brandId}`));
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  // TODO: CLAIM ----------------------------------------------------------------

  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

  const handleOpenConfirmation = () => {
    setIsConfirmationOpen(true);
  };
  const handleCloseConfirmation = () => {
    setIsConfirmationOpen(false);
  };

  const handleConfirm = () => {
    handleCloseConfirmation();
    handleClaimVoucher(voucher);
  }


  const handleClaimVoucher = async () => {
    const claimURL = `voucher/claim`;
    try {
      const resp = await postDataWithAuth(claimURL, {
        voucherId: voucherID,
        userId: userId,
      });
      setClaimedVoucher({
        ...voucher,
        code: resp.voucherCode,
        brand: brand.name,
      });
      setOpenModal(true);
    } catch (e) {
      console.error(e);
      toast.error("You have reached the voucher's claim limit!");
    }
  };




  // ----------------------------------------------------------------------

  return isLoading ? (
    <div></div>
  ) : (
    <>
      <Helmet>
        <title> HCN Perks | Vouchers </title>
      </Helmet>

      <ToastContainer />
      <Card sx={{ margin: 1 }}>
        <CardMedia
          component="img"
          alt={`Voucher ${voucher.voucherId}`}
          height="200"
          src={voucher.imageUrl === "" || !voucher.imageUrl ? "/assets/HCN_logo.png" : voucher.imageUrl}
        />
        <CardContent>
          <Typography textAlign={'center'} variant="h4" mb={2}>
            {voucher.name}
          </Typography>
          <Divider>
            <Chip label="Details" />
          </Divider>
          <Typography mt={2} variant="body2" color="textSecondary">
            Claim Limit: <b>{voucher.userClaimLimit === 0 ? "Unlimited" : voucher.userClaimLimit}</b>
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Expires At: <b>{voucher.expiresAt ? fDateTime(dayjs(voucher.expiresAt)) : "N/A"}</b>
          </Typography>
          <Typography mt={2} mb={2} variant="body2" color="textSecondary">
            <b>{brand.name ? brand.name.toUpperCase() : brand.name}</b>: {brand.description}
          </Typography>

          <Divider sx={{ mt: 5, mb: 2 }}>
            <Chip label="How To Claim" />
          </Divider>
          {voucher.customClaimText ? formatVoucherText(voucher.customClaimText) : (
            <Typography variant="body2" color="textSecondary">
              Simply click the claim button below and show your screen to the merchant!
            </Typography>
          )}

          <Divider sx={{ mt: 5, mb: 2 }}>
            <Chip label="Terms & Conditions" />
          </Divider>

          {voucher.termsAndConditions ? formatVoucherText(voucher.termsAndConditions) : (
            <Typography variant="caption" color="textSecondary">
              <i>Oops. Nothing here yet.</i>
            </Typography>
          )}
        </CardContent>
      </Card>

      <Stack
        direction="row"
        spacing={3}
        sx={{ marginTop: 2, marginLeft: 2 }}>
        <Button
          onClick={handleOpenConfirmation}
          variant="contained"
          color="primary"
        >
          Claim
        </Button>
      </Stack>

      <ConfirmationDialog
        isConfirmationOpen={isConfirmationOpen}
        handleCloseConfirmation={handleCloseConfirmation}
        handleConfirm={handleConfirm}
        title={"Confirm Voucher Claim"}
        message={"Please only click confirm if you are using the voucher now."}
      />

      <VoucherClaimModal
        open={openModal}
        handleClose={() => setOpenModal(false)}
        claimedVoucher={claimedVoucher}
      />

    </>
  );
};

export default VoucherDetails;