import { ClimbingBoxLoader } from "react-spinners";

export default function CustomSpinner() {
    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '70vh', // Use viewport height to cover the entire visible area
            }}
        >
            <ClimbingBoxLoader color='#252363' size={30} />
        </div>
    )
}