import { Typography } from '@mui/material';
import parse from 'html-react-parser'; // You might need to install this package

// converts all dashes to bullet points
// also converts all urls to clickable links
export function formatVoucherText(str) {
  if (!str) return "";
  var lines = str.split('\n');
  var elements = [];
  lines.forEach((line, index) => {
    if (line.trim().startsWith('-')) {
      var item = line.substring(1);
      elements.push(
        <li key={index}>
          <Typography variant="body2" color="textSecondary">
            {parse(makeUrlsClickable(item))}
          </Typography>
        </li>
      );
    } else if (line === "") {
      elements.push(<br key={index} />)
    } else {
      elements.push(
        <Typography key={index} variant="body2" color="textSecondary" sx={{ whiteSpace: 'pre-line' }} >
          {parse(makeUrlsClickable(line))}
        </Typography>
      );

    }
  });

  const listItemsIndex = elements.findIndex(element => element.type === 'li');
  if (listItemsIndex !== -1) {
    elements.splice(listItemsIndex, elements.length - listItemsIndex, <ul key="list" style={{ marginLeft: 20 }}>{elements.slice(listItemsIndex)}</ul>);
  }

  return <>{elements}</>;
}

// converts possible urls to to links
function makeUrlsClickable(text) {
  const urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/ig;
  return text.replace(urlRegex, function (url) {
    return '<a href="' + url + '" target="_blank" rel="noopener noreferrer">' + url + '</a>';
  });
}