import axios from "axios";


export async function GetLocation(postalCode) {
    var oneMapUrl = "https://www.onemap.gov.sg/api/common/elastic/search?returnGeom=Y&getAddrDetails=Y";
    var url = `${oneMapUrl}&searchVal=${postalCode}`;
    var resp = await axios.get(url);
    var data = resp.data;
    if (data && data.results && data.results.length > 0) {
        return data.results[0];
    }
    return {
        "ADDRESS": "ADDRESS NOT FOUND",
        "LONGITUDE": 0,
        "LATITUDE": 0,
    };
}

export function IsPostalCode(input) {
    const postalCodePattern = /^\d{6}$/;
    return postalCodePattern.test(input);
}

// SAMPLE RESP:
// {
//     "found": 1,
//     "totalNumPages": 1,
//     "pageNum": 1,
//     "results": [
//      {
//         "SEARCHVAL": "KHONG GUAN BUILDING",
//         "BLK_NO": "2",
//         "ROAD_NAME": "MACTAGGART ROAD",
//         "BUILDING": "KHONG GUAN BUILDING",
//         "ADDRESS": "2 MACTAGGART ROAD KHONG GUAN BUILDING SINGAPORE 368078",
//         "POSTAL": "368078",
//         "X": "33831.6805178765",
//         "Y": "35244.5368774887",
//         "LATITUDE": "1.33501303241669",
//         "LONGITUDE": "103.885719577713"
//      }
//      ]
// }