import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import React from 'react';
import useCtx from '../../contexts/PerksContext';
import { getMembershipId } from '../../utils/membership';



const cardStyle = {
    maxWidth: 345,
    position: 'relative',
    height: 175,
    backgroundImage: 'url(/assets/hcn_background_light.png)',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
};


export function MembershipCard() {
    const { user } = useCtx();
    var title = "HEPMIL CREATOR"
    if (user.permissionType !== 'Creator') {
        title = "HEPMIL STAFF"
    }
    return (
        <Card sx={cardStyle}>
            <CardContent sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <Box flexGrow={1} display="flex" flexDirection="column" justifyContent="space-between">
                    <Box>
                        <Typography variant="h5" component="div" color={'whitesmoke'}>
                            {`${user.firstName} ${user.lastName}`}
                        </Typography>
                        <Typography variant="body2" color="lightgrey" sx={{ mb: 2 }}> {/* Adjust marginBottom as needed */}
                            {title}
                        </Typography>
                    </Box>
                    <Typography variant="caption" color="lightgrey">
                        Account ID: {getMembershipId(user.userId, new Date(user.createdAt))}
                    </Typography>
                </Box>
                <Box sx={{ position: 'absolute', top: 12, right: 12 }}>
                    <img src='/assets/HCN_logo.png' alt="Logo" style={{ width: 50, height: 50, borderRadius: 50 }} />
                </Box>
            </CardContent>
        </Card>
    );
}

