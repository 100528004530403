import PropTypes from 'prop-types';
// @mui
import {
    Box,
    Button,
    Checkbox,
    Divider,
    Drawer,
    FormControlLabel,
    FormGroup,
    IconButton,
    Stack,
    Typography
} from '@mui/material';
// components
import Iconify from '../../components/iconify';
import Scrollbar from '../../components/scrollbar';
import { applySortFilter, getComparator } from '../../utils/sortFilter';


// ----------------------------------------------------------------------

ShopFilterSidebar.propTypes = {
    openFilter: PropTypes.bool,
    onOpenFilter: PropTypes.func,
    onCloseFilter: PropTypes.func,
    brandFilters: PropTypes.array,
    setBrandFilters: PropTypes.func,
    brands: PropTypes.array,
    categoryFilters: PropTypes.array,
    setCategoryFilters: PropTypes.func,
    categories: PropTypes.array,
};

export default function ShopFilterSidebar({
    openFilter,
    onOpenFilter,
    onCloseFilter,
    brandFilters,
    setBrandFilters,
    brands,
    categoryFilters,
    setCategoryFilters,
    categories,
}) {

    const handleClear = () => {
        setBrandFilters([]);
    }

    // BRANDS -------------------------------------------------------------------------

    const handleBrandCheckboxChange = (event) => {
        const brandId = event.target.value;
        if (event.target.checked) {
            setBrandFilters([...brandFilters, parseInt(brandId)]);
        } else {
            setBrandFilters(brandFilters.filter(id => id !== parseInt(brandId)));
        }
    }

    const sortedBrands = applySortFilter(brands, getComparator("asc", "name"));

    // CATEGORIES -------------------------------------------------------------------------


    const handleCategoryCheckboxChange = (event) => {
        const catId = event.target.value;
        if (event.target.checked) {
            setCategoryFilters([...categoryFilters, parseInt(catId)]);
        } else {
            setCategoryFilters(categoryFilters.filter(id => id !== parseInt(catId)));
        }
    }
    
    const sortedCategories = applySortFilter(categories, getComparator("asc", "name"));

    //--------------------------------------------------------------------------------

    return (
        <>
            <Button disableRipple color="inherit" endIcon={<Iconify icon="ic:round-filter-list" />} onClick={onOpenFilter}>
                Filters&nbsp;
            </Button>

            <Drawer
                anchor="right"
                open={openFilter}
                onClose={onCloseFilter}
                PaperProps={{
                    sx: { width: 280, border: 'none', overflow: 'hidden' },
                }}
            >
                <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ px: 1, py: 2 }}>
                    <Typography variant="subtitle1" sx={{ ml: 1 }}>
                        Filters
                    </Typography>
                    <IconButton onClick={onCloseFilter}>
                        <Iconify icon="eva:close-fill" />
                    </IconButton>
                </Stack>

                <Divider />

                <Scrollbar>
                    <Stack spacing={3} sx={{ p: 3 }}>
                        <div>
                            <Typography variant="subtitle1" gutterBottom>
                                Categories
                            </Typography>
                            <FormGroup>
                                {sortedCategories.map((c) => (
                                    <FormControlLabel
                                        key={c.categoryId}
                                        control={<Checkbox
                                            value={c.categoryId}
                                            onChange={handleCategoryCheckboxChange}
                                            checked={categoryFilters.includes(c.categoryId)}
                                        />}
                                        label={c.name}
                                    />
                                ))}
                            </FormGroup>
                        </div>
                        <div>
                            <Typography variant="subtitle1" gutterBottom>
                                Brands
                            </Typography>
                            <FormGroup>
                                {sortedBrands.map((b) => (
                                    <FormControlLabel
                                        key={b.brandId}
                                        control={<Checkbox
                                            value={b.brandId}
                                            onChange={handleBrandCheckboxChange}
                                            checked={brandFilters.includes(b.brandId)}
                                        />}
                                        label={b.name}
                                    />
                                ))}
                            </FormGroup>
                        </div>

                    </Stack>
                </Scrollbar>

                <Box sx={{ p: 3 }}>
                    <Button
                        fullWidth
                        size="large"
                        type="submit"
                        color="inherit"
                        variant="outlined"
                        startIcon={<Iconify icon="ic:round-clear-all" />}
                        onClick={handleClear}
                    >
                        Clear All
                    </Button>
                </Box>
            </Drawer>
        </>
    );
}