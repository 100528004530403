import { filter } from 'lodash';

export function descendingComparator(a, b, orderBy) {
    if (typeof a[orderBy] === 'string' && typeof b[orderBy] === 'string') {
        const aStartsWithAlpha = /^[a-zA-Z]/.test(a[orderBy]);
        const bStartsWithAlpha = /^[a-zA-Z]/.test(b[orderBy]);

        if (aStartsWithAlpha && bStartsWithAlpha) {
            return a[orderBy].toLowerCase().localeCompare(b[orderBy].toLowerCase());
        }
    }
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

export function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => -descendingComparator(a, b, orderBy)
        : (a, b) => descendingComparator(a, b, orderBy);
}

export function applySortFilter(array, comparator, query) {
    var filtered = array;
    if (query) {
        filtered = filter(array, (u) => u.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    const stabilizedThis = filtered.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}