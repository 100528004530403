import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import { StyledChart } from './components/chart';
import ScrollToTop from './components/scroll-to-top';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { CtxProvider } from './contexts/PerksContext';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { VoucherProvider } from './contexts/VoucherContext';

// ----------------------------------------------------------------------

export default function App() {
  const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
  return (
    <HelmetProvider>
      <BrowserRouter>
        <CtxProvider>
          <VoucherProvider>
            <GoogleOAuthProvider clientId={googleClientId}>
              <ThemeProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <ScrollToTop />
                  <StyledChart />
                  <Router />
                </LocalizationProvider>
              </ThemeProvider>
            </GoogleOAuthProvider>
          </VoucherProvider>
        </CtxProvider>
      </BrowserRouter>
    </HelmetProvider>
  );
}
