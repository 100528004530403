import { Controller, useForm } from "react-hook-form"
import { useState, useEffect} from "react";
import useCtx from "../../contexts/PerksContext";
import { Container, Grid, Typography, TextField, Stack, MenuItem, Button, Autocomplete } from "@mui/material";
import ImageUploader from '../../components/form/ImageUploader';
import getImageBase64 from '../../components/form/ConvertImage';

export default function BrandCreationPage() {

    const { control, handleSubmit, reset } = useForm();
    const { postDataWithAuth, fetchDataWithAuth } = useCtx();
    const [imageURL, setImageURL] = useState('');
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        const fetch = async () => {
            try {
                const url = "category/all";
                setCategories(await fetchDataWithAuth(url));
            } catch (error) {
                alert(`Failed to fetch brands: ${JSON.stringify(error.response)}`);
            }
        }
        fetch();
    }, [])

    const parsedCategories = categories.map((c) => {
        return {
            label: c.name,
            value: c.categoryId
        };
    });

    const handleSubmitBrand = async (data) => {
        try {
            for (const key in data) {
                if (data[key] === "") {
                    data[key] = null;
                }
            }
            data.categoryId = data.categoryId.value;
            const base64ImageString = await getImageBase64(imageURL);
            data["imageUrl"] = base64ImageString;
            const url = `brand/new`;
            await postDataWithAuth(url, data);
            reset();
            alert(`Successfully created brand!`);
        } catch (error) {
            alert(`Failed to create brand: ${error}`)
        }
    }

    return (
        <Container>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12}>
                    <Typography variant="h4" align="center" gutterBottom>
                        Add Brand
                    </Typography>
                    <form onSubmit={handleSubmit(handleSubmitBrand)}>
                        <Stack
                            spacing={2}
                            noValidate
                            autoComplete="off"
                        >
                            <Controller
                                name="name"
                                control={control}
                                defaultValue=""
                                render={({ field }) => <TextField {...field} label="Name" fullWidth />}
                            />
                            <Controller
                                name="description"
                                control={control}
                                defaultValue=""
                                render={({ field }) => <TextField {...field} label="Description" fullWidth multiline maxRows={10} />}
                            />
                            <Controller
                                rules={{ required: true }}
                                name="categoryId"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <Autocomplete
                                    onChange={(event, item) => {
                                        onChange(item);
                                    }}
                                    value={value || null}
                                    options={parsedCategories}
                                    getOptionLabel={(option) => option.label}
                                    isOptionEqualToValue={(option, value) => {
                                        return option.label === value.label;
                                    }}
                                    renderInput={(params) => <TextField {...params} label="Categories" margin="normal" />}
                                    />
                                )}
                                />
                             <ImageUploader
                                 name="imageUrl"
                                 control={control}
                                 defaultValue={null}
                                 imageURL = {imageURL}
                                 setImageURL={setImageURL}
                            />

                            <Button type="submit" variant="contained" color="primary">
                                Submit
                            </Button>
                        </Stack>
                    </form>
                </Grid>
            </Grid>
        </Container>
    );
}