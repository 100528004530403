import {
  Card,
  Checkbox,
  IconButton,
  MenuItem,
  Paper,
  Popover,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';

import { addHours, format } from 'date-fns';
import { filter } from 'lodash';
import { useEffect, useState } from 'react';
import ConfirmationDialog from '../../components/confirmation-dialog/confirmation-dialog';
import Iconify from '../../components/iconify';
import ListHead from '../../components/table/ListHead';
import { ListToolbar } from '../../components/table/ListToolbar';
import useCtx from '../../contexts/PerksContext';
import { applySortFilter, getComparator } from '../../utils/sortFilter';
import CustomSpinner from '../../components/spinner/Spinner';

// HEADERS ----------------------------------------------------------------------

const transactionHeaders = [
  { id: 'transactionId', label: 'ID' },
  { id: 'voucherName', label: 'Voucher' },
  { id: 'brandName', label: 'Brand' },
  { id: 'claimant', label: 'Claimant' },
  { id: 'permissionType', label: 'Permission' },
  { id: 'claimedAt', label: 'Claimed At' },
  { id: "" },
]

// ----------------------------------------------------------------------

export function VoucherTransactionsTable() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(true);
  const [order, setOrder] = useState('desc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('transactionId');
  const [filterName, setFilterName] = useState('');
  const [menu, setMenu] = useState({});
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [transactionCount, setTransactionCount] = useState(0);
  const [filterPermission, setFilterPermission] = useState("Staff,Creator");
  const { deleteDataWithAuth, fetchDataWithAuth, fetchPagedData } = useCtx();
  const displayedTransactions = transactions;


  // ROW MENU ---------------------------------------------------------------

  const handleOpenMenu = (event, transactionId) => {
    setMenu({
      target: event.currentTarget,
      transactionId: transactionId,
    });
  };

  const handleCloseMenu = () => {
    setMenu({});
  };


  // DELETE TRANSACTION + CONFIRMATION --------------------------------------------------

  const handleDeleteTransaction = async () => {
    try {
      await deleteDataWithAuth(`vouchertransaction/${menu.transactionId}`);
      alert(`Successfully deleted transaction ID ${menu.transactionId}`);
      handleCloseMenu();
    } catch (error) {
      alert("Failed to delete transaction :(");
      console.error(error);
    }
  }

  const handleOpenConfirmation = () => {
    setIsConfirmationOpen(true);
  };
  const handleCloseConfirmation = () => {
    setIsConfirmationOpen(false);
  };

  const handleConfirm = async () => {
    handleCloseConfirmation();
    await handleDeleteTransaction();
  }


  // TABLE ---------------------------------------------------------------

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = displayedTransactions.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const handleFilterByPermission = (event) => {
    setPage(0);
    setFilterPermission(event.target.value);
  }

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - transactionCount) : 0;

  var filteredTransactions = filter(displayedTransactions, (u) =>
    u.voucherName.toLowerCase().indexOf(filterName.toLowerCase()) !== -1 ||
    u.brandName.toLowerCase().indexOf(filterName.toLowerCase()) !== -1 ||
    u.claimant.toLowerCase().indexOf(filterName.toLowerCase()) !== -1
  );
  filteredTransactions = applySortFilter(filteredTransactions, getComparator(order, orderBy), "");

  const isNotFound = !filteredTransactions.length && !!filterName;

  useEffect(() => {
    const fetchData = async () => {
      var filteredTransactions;
      try {
        // Set modified transaction list
        const params = [
          { name: 'searchTerm', value: filterName },
          { name: 'permissionType', value: filterPermission === "All" ? "" : filterPermission }
        ];

        params.push(
          { name: 'sortBy', value: orderBy },
          { name: 'sortOrder', value: order }
        );
        const transactionList = await fetchPagedData(`vouchertransaction/all`,
          page + 1,
          rowsPerPage,
          ...params
        );
        setTransactionCount(transactionList.totalRecords);
        filteredTransactions = transactionList.data.map((transaction, idx) => {
          const claimaintName = transaction.firstName + " " + transaction.lastName;
          return {
            ...transaction,
            claimant: claimaintName,
            permissionType: transaction.permissionType,
            voucherName: transaction.voucherName,
            brandName: transaction.brandName
          }
        });
        filteredTransactions = filteredTransactions.sort((a, b) => order === 'desc' ? b[orderBy] - a[orderBy] : a[orderBy] - b[orderBy]);
        setTransactions(filteredTransactions);
        setIsLoading(false);
      } catch (error) {
        console.error(`Failed to set filtered transactions: ${error}`);
      }
    }
    fetchData();
  }, [orderBy, order, filterName, filterPermission, page, rowsPerPage])
  //---------------------------------------------------------------


  return isLoading ? <CustomSpinner /> : (
    <>
      <Card>
        <ListToolbar
          numSelected={selected.length}
          filterName={filterName}
          tableName={"transactions"}
          onFilterName={handleFilterByName}
          placeholder={"Search transactions..."}
          filterPermission={filterPermission}
          handleFilterByPermission={handleFilterByPermission}
        />

        <TableContainer sx={{ minWidth: 800 }}>
          <Table>
            <ListHead
              order={order}
              orderBy={orderBy}
              headLabel={transactionHeaders}
              rowCount={transactionCount}
              numSelected={selected.length}
              onRequestSort={handleRequestSort}
              onSelectAllClick={handleSelectAllClick}
            />
            <TableBody>
              {transactions.map((row) => {
                const { transactionId, voucherName, brandName, claimant, claimedAt, permissionType } = row;
                const selectedTransaction = selected.indexOf(transactionId) !== -1;

                return (
                  <TableRow hover key={transactionId} tabIndex={-1} role="checkbox" selected={selectedTransaction}>
                    <TableCell padding="checkbox">
                      <Checkbox checked={selectedTransaction} onChange={(event) => handleClick(event, transactionId)} />
                    </TableCell>

                    <TableCell component="th" scope="row" padding="none">
                      <Stack direction="row" alignItems="center" spacing={2}>
                        <Typography variant="subtitle2" noWrap>
                          {transactionId}
                        </Typography>
                      </Stack>
                    </TableCell>
                    <TableCell align="left">{voucherName}</TableCell>
                    <TableCell align="left">{brandName}</TableCell>
                    <TableCell align="left">{claimant}</TableCell>
                    <TableCell align="left">{permissionType}</TableCell>
                    <TableCell align="left">{format(addHours(new Date(claimedAt), 8), "dd MMM yyyy, p")}</TableCell>
                    <TableCell align="right">
                      <IconButton size="large" color="inherit" onClick={(event) => handleOpenMenu(event, transactionId)}>
                        <Iconify icon={'eva:more-vertical-fill'} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>

            {isNotFound && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                    <Paper
                      sx={{
                        textAlign: 'center',
                      }}
                    >
                      <Typography variant="h6" paragraph>
                        Not found
                      </Typography>

                      <Typography variant="body2">
                        No results found for &nbsp;
                        <strong>&quot;{filterName}&quot;</strong>.
                        <br /> Try checking for typos or using complete words.
                      </Typography>
                    </Paper>
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={transactionCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>


      <Popover
        open={Boolean(menu.target)}
        anchorEl={menu.target}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem onClick={handleOpenConfirmation}>
          <Iconify icon={'eva:book-open-fill'} sx={{ mr: 2 }} />
          Delete transaction
        </MenuItem>
      </Popover>


      <ConfirmationDialog
        isConfirmationOpen={isConfirmationOpen}
        handleCloseConfirmation={handleCloseConfirmation}
        handleConfirm={handleConfirm}
        title={"Confirm Delete"}
        message={"WARNING: Transaction will be forever lost. Do you still wish to proceed?"}
      />


    </>


  )
}