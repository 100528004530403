import {
    Button,
    Card,
    CardContent,
    CardMedia,
    Divider,
    Stack,
    Typography,
    useMediaQuery,
    useTheme
} from '@mui/material';
import dayjs from 'dayjs';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import ConfirmationDialog from '../../components/confirmation-dialog/confirmation-dialog';
import { fDateTime } from '../../utils/formatTime';


// ----------------------------------------------------------------------


export default function VoucherCard({ voucher, handleClaimVoucher }) {
    const {
        voucherId,
        name,
        expiresAt,
        imageUrl,
        userClaimLimit,
        brand,
    } = voucher;

    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
    const handleOpenConfirmation = () => {
        setIsConfirmationOpen(true);
    };
    const handleCloseConfirmation = () => {
        setIsConfirmationOpen(false);
    };

    const handleConfirm = () => {
        handleCloseConfirmation();
        handleClaimVoucher(voucher);
    }

    //-------------------------------------------------------------------------

    const theme = useTheme();
    const isMobileView = useMediaQuery(theme.breakpoints.down('sm'));

    //-------------------------------------------------------------------------

    return (
        <>
            <Card>
                <CardMedia
                    component="img"
                    alt={`Voucher ${voucherId}`}
                    height="200"
                    src={imageUrl === "" || !imageUrl ? "/assets/HCN_logo.png" : imageUrl}
                />
                <CardContent>
                    <Typography variant={isMobileView ? 'h3' : 'h4'}>
                        {voucher.brand}
                    </Typography>
                    <Typography variant="caption">
                        <i>{voucher.category}</i>
                    </Typography>
                    <Typography variant="body2" mt={2} mb={2} height={isMobileView ? 'auto' : 60}>
                        {name}
                    </Typography>
                    <Divider />
                    <Typography mt={2} variant="body2" color="textSecondary">
                        Claim Limit: <b>{userClaimLimit === 0 ? "Unlimited" : userClaimLimit }</b>
                    </Typography>
                    <Typography mb={2} variant="body2" color="textSecondary">
                        Expires At: <b>{expiresAt ? fDateTime(dayjs(expiresAt)) : "N/A"}</b>
                    </Typography>
                    <Stack
                        direction="row"
                        spacing={2}
                        sx={{ marginTop: 1 }}
                    >
                        <Button
                            component={Link}
                            to={`/dashboard/vouchers/${voucherId}`}
                            variant="contained"
                            color="primary"
                        >
                            View
                        </Button>
                        <Button
                            onClick={handleOpenConfirmation}
                            variant="contained"
                            color="primary"
                        >
                            Claim
                        </Button>
                    </Stack>
                </CardContent>

            </Card>

            <ConfirmationDialog 
                isConfirmationOpen={isConfirmationOpen}
                handleCloseConfirmation={handleCloseConfirmation}
                handleConfirm={handleConfirm}
                title={"Confirm Voucher Claim"}
                message={"Please only click confirm if you are using the voucher now."}
            />
        </>
    );
}
