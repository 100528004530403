import axios from 'axios';
import { createContext, useContext, useEffect, useState } from 'react';
import { getLocalStorageWithExpiry } from '../utils/localStorage';
import { getBaseUrl } from '../utils/url';


const PerksContext = createContext();

export function CtxProvider({ children }) {
  const [jwt, setJwt] = useState(getLocalStorageWithExpiry("jwt"));
  const [userId, setUserId] = useState(getLocalStorageWithExpiry("userId"));
  const [user, setUser] = useState({});

  useEffect(() => {
    async function refresh() {
      try {
        if (userId && userId !== "" && jwt && jwt !== "") {
          setUserId(userId);
          await refreshUser();
        }
      } catch (e) {
        console.error("Error refreshing context");
      }
    }
    refresh();
  }, [userId, jwt]);

  const refreshUser = async () => {
    if (jwt && userId) {
      const url = `user/${userId}`;
      try {
        setUser(await fetchDataWithAuth(url))
      } catch (error) {
        console.error('Error fetching user:', error);
      }
    }
  };

  const fetchDataWithAuth = async (url) => {
    const resp = await axios.get(`${getBaseUrl()}/${url}`, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });
    return resp.data;
  }

  const fetchPagedData = async (url, pageNum, pageSize, ...opts) => {
    const params = new URLSearchParams();
    params.append('pageNumber', pageNum ? pageNum.toString() : "1");
    params.append('pageSize', pageSize ? pageSize.toString() : "50");
    opts.forEach((param) => {
      params.append(param.name, param.value);
    });
    const resp = await axios.get(`${getBaseUrl()}/${url}?${params.toString()}`, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });
    return resp.data;
  }

  const fetchAllData = async (url, ...opts) => {
    const params = new URLSearchParams();
    var page = 0;
    var limit = 50;
    var total = 1;
    params.append('pageNumber', page);
    params.append('pageSize', limit);
    opts.forEach((param) => {
      params.append(param.name, param.value);
    });
    var data = [];
    while (page < total) {
      page++;
      const resp = await axios.get(`${getBaseUrl()}/${url}?${params.toString()}`, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      });
      data = data.concat(resp.data.data);
    }
    return data;
  }
  
  const postDataWithAuth = async (url, body, customHeaders) => {
    const resp = await axios.post(`${getBaseUrl()}/${url}`, body, {
      headers: {
        Authorization: `Bearer ${jwt}`,
        ...customHeaders,
      },
    });
    return resp.data;
  }

  const putDataWithAuth = async (url, body, customHeaders) => {
    const resp = await axios.put(`${getBaseUrl()}/${url}`, body, {
      headers: {
        Authorization: `Bearer ${jwt}`,
        ...customHeaders,
      },
    });
    return resp.data;
  }

  const deleteDataWithAuth = async (url) => {
    const resp = await axios.delete(`${getBaseUrl()}/${url}`, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });
    return resp.data;
  }

  return (
    <PerksContext.Provider value={{
      jwt,
      setJwt,
      user,
      setUser,
      userId,
      setUserId,
      refreshUser,
      fetchDataWithAuth,
      fetchPagedData,
      fetchAllData,
      postDataWithAuth,
      putDataWithAuth,
      deleteDataWithAuth,
    }}>
      {children}
    </PerksContext.Provider>
  );
}

export default function useCtx() {
  return useContext(PerksContext);
}