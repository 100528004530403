// component
import SvgColor from '../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'vouchers',
    path: '/dashboard/vouchers',
    icon: icon('ic_cart'),
    admin: false,
  },
  {
    title: 'near me',
    path: '/dashboard/near-me',
    icon: icon('ic_maps'),
    admin: false,
  },
  {
    title: 'users',
    path: '/dashboard/users',
    icon: icon('ic_user'),
    admin: true,
  },
  {
    title: 'brands',
    path: '/dashboard/brands',
    icon: icon('ic_blog'),
    admin: true,
  },
  {
    title: 'manage vouchers',
    path: '/dashboard/manage-vouchers',
    icon: icon('ic_list'),
    admin: true,
  },
  {
    title: 'transactions',
    path: '/dashboard/transactions',
    icon: icon('ic_analytics'),
    admin: true,
  },
];

export default navConfig;
