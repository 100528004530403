export default async function getImageBase64(imageUrlOrBlob) {
    try {
      let blob;
  
      if (typeof imageUrlOrBlob === 'string') {
        const response = await fetch(imageUrlOrBlob);
        blob = await response.blob();
      } else if (imageUrlOrBlob instanceof Blob) {
        blob = imageUrlOrBlob;
      } else {
        throw new Error('Invalid input type. Expected image URL or Blob.');
      }
  
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          const base64Data = reader.result.split(',')[1];
          resolve(base64Data);
        };
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    } catch (error) {
      console.error('Error converting image to Base64:', error);
      throw error;
    }
  }