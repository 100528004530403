import { Box, Container, Grid, Typography } from '@mui/material';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import React, { useEffect, useState } from 'react';
import "react-multi-carousel/lib/styles.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Iconify from '../../components/iconify';
import useCtx from '../../contexts/PerksContext';
import { MapCarousel } from '../../sections/map/Carousel';
import { CustomInfoWindow } from '../../sections/map/CustomInfoWindow';
import { CustomMarker } from '../../sections/map/CustomMarker';
import { MapDrawer } from '../../sections/map/MapDrawer';
import { getLocalStorageWithExpiry, setLocalStorageWithExpiry } from '../../utils/localStorage';

const containerStyle = {
    width: '100%',
    height: '60vh'
};

if (window.innerWidth >= 768) {
    containerStyle.height = '500px';
}

// keys for storing user settings in their browser
const allLocationsKey = "perks_map_settings_all_locations";
const isTrackingKey = "perks_map_settings_is_tracking";

export function NearMe() {
    const [locations, setLocations] = useState([]);
    const [slides, setSlides] = useState([]); // carousel slides
    const [selectedVoucher, setSelectedVoucher] = useState(null); // selected voucher for carousel
    const [infoWindow, setInfoWindow] = useState(null); // location for infowindow on the map
    const [showAllLocations, setShowAllLocations] = useState(getLocalStorageWithExpiry(allLocationsKey) ?? false); // returns all locations for a voucher instead of only the nearest
    const [isTracking, setIsTracking] = useState(getLocalStorageWithExpiry(isTrackingKey) ?? false); // if true, moves to closest voucher location when carousel slides
    const [drawer, setDrawer] = useState(false); // settings drawer
    const { fetchPagedData, fetchAllData } = useCtx();
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY
    });

    // sets center and user location at our office if location is not given
    const [center, setCenter] = useState(null);
    const [userLocation, setUserLocation] = useState(null);


    // get information for carousel
    useEffect(() => {
        const fetch = async () => {
            navigator.geolocation.getCurrentPosition(async (position) => {
                var params = [
                    { name: "userLatitude", value: position.coords.latitude },
                    { name: "userLongtitude", value: position.coords.longitude },
                    { name: "singleLocation", value: true }
                ];
                var locs = await fetchAllData(`voucher/location/all`, ...params);
                setSlides(locs);
            }, () => { });
        };
        fetch();
    }, [])

    // get information for voucher location markers
    useEffect(() => {

        // update local storage
        setLocalStorageWithExpiry(allLocationsKey, showAllLocations, 999999);
        setLocalStorageWithExpiry(isTrackingKey, isTracking, 999999);

        const fetch = async () => {
            navigator.geolocation.getCurrentPosition(async (position) => {
                const coordinates = { lat: position.coords.latitude, lng: position.coords.longitude };
                setUserLocation(coordinates);

                // only set the center (focus of the map) for the first time
                if (!center) {
                    setCenter(coordinates);
                }

                var params = [];
                params.push({ name: "userLatitude", value: position.coords.latitude })
                params.push({ name: "userLongtitude", value: position.coords.longitude })
                if (selectedVoucher) {
                    params.push({ name: 'voucherId', value: selectedVoucher })
                }
                if (!showAllLocations) {
                    params.push({ name: "singleLocation", value: true })
                }
                var currPage = 0;
                var pageLimit = 50;
                var totalPages = 1;
                var locs = [];
                while (currPage < totalPages) {
                    currPage++;
                    const resp = await fetchPagedData(`voucher/location/all`, currPage, pageLimit, ...params);
                    locs = locs.concat(resp.data);
                    totalPages = resp.totalPages;
                }
                setLocations(locs);
            }, () => {
                toast.error("Oops, location access denied :(");
            });
        };
        fetch();
    }, [selectedVoucher, showAllLocations, isTracking, center]);

    return isLoaded ? (
        <>
            <ToastContainer />
            <Container >
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    mb: 1,
                }}>
                    <Grid container alignItems="center" justifyContent="center">
                        <Grid item xs>
                            <Typography variant="h4" gutterBottom align="center">
                                Vouchers Near You
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Iconify icon="eva:settings-fill" onClick={() => setDrawer(true)} />
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={{ position: 'relative' }}>

                    <GoogleMap
                        mapContainerStyle={containerStyle}
                        center={center}
                        zoom={13}
                        onClick={() => setInfoWindow(null)}
                    >
                        <Marker
                            icon={new window.google.maps.MarkerImage(
                                // "https://cdn-icons-png.flaticon.com/512/574/574512.png",
                                "https://cdn-icons-png.flaticon.com/512/4605/4605368.png",
                                // "https://cdn-icons-png.flaticon.com/512/3284/3284735.png",
                                null, /* size is determined at runtime */
                                null, /* origin is 0,0 */
                                null, /* anchor is bottom center of the scaled image */
                                new window.google.maps.Size(55, 55)
                            )}
                            position={userLocation}
                            animation={window.google.maps.Animation.DROP}

                        />
                        {locations.map((location, index) => (
                            <CustomMarker
                                key={index}
                                location={location}
                                setInfoWindow={setInfoWindow}
                            />
                        ))}
                        {infoWindow && (
                            <CustomInfoWindow location={infoWindow} setInfoWindow={setInfoWindow} />
                        )}
                    </GoogleMap>
                </Box>

                {slides.length > 0 && (
                    <MapCarousel
                        slides={slides}
                        setSelectedVoucher={setSelectedVoucher}
                        setInfoWindow={setInfoWindow}
                        setCenter={setCenter}
                        isTracking={isTracking}
                        userLocation={userLocation}
                    />
                )}
            </Container>

            <MapDrawer
                open={drawer}
                onClose={() => setDrawer(false)}
                showAllLocations={showAllLocations}
                setShowAllLocations={setShowAllLocations}
                isTracking={isTracking}
                setIsTracking={setIsTracking}
            />
        </>
    ) : <></>
}