import {
    Button
} from '@mui/material';
import { CSVLink } from 'react-csv';
import { CircleLoader } from 'react-spinners';
import Iconify from '../iconify/Iconify';

export default function DownloadCsvButton({
    data,
    fileName,
    handleDownload,
    isDownloading,
    buttonText,
}) {
    var icon = <Iconify icon="eva:download-outline" />;
    if (isDownloading) {
        buttonText = (
            <>
                <CircleLoader size={20} color={'#fff'} loading={isDownloading} />&nbsp;&nbsp;Retrieving data
            </>
        );
        icon = null;
    }
    if (data.length > 0) {
        icon = <Iconify icon="eva:file-text-outline" />
    }
    return (
        <Button
            variant="contained"
            startIcon={icon}
            onClick={handleDownload}
        >
            {data.length > 0 ? (
                <CSVLink data={data} filename={fileName} style={{
                    color: 'white',
                    textDecoration: 'none',
                }}>
                    File is ready. Click again to download
                </CSVLink>
            ) : (
                <>{buttonText}</>
            )}
        </Button>
    )
}