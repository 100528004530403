import {
    Avatar,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Divider,
    Typography
} from '@mui/material';
import { useState } from 'react';
import { Toast } from '../../components/toast';
import useCtx from '../../contexts/PerksContext';
import getImageBase64 from '../../components/form/ConvertImage';
import { timeClockClasses } from '@mui/x-date-pickers';

export const AccountProfile = () => {
    const { user, putDataWithAuth } = useCtx();
    const [displayImage, setDisplayImage] = useState("");
    const [toast, setToast] = useState(false);

    // TOAST ------------------------------------------------------------------------------

    const handleCloseToast = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setToast(false);
    }

    // DISPLAY PIC UPLOAD ----------------------------------------------------------------------

    const handleUpload = async (event) => {
        const file = event.target.files[0];
        if (file !== undefined && file.type.startsWith('image/')) {
            setDisplayImage(URL.createObjectURL(file));
        }
        try {
            const newDisplayImage = await getImageBase64(URL.createObjectURL(file));
            await putDataWithAuth(`user/upload/${user.userId}`, {
                displayImageUrl: newDisplayImage,
            });
        } catch (e) {
            setToast(true);
        }
        event.target.value = null;
    }

    return (
        <>
            <Card>
                <CardContent>
                    <Box
                        sx={{
                            alignItems: 'center',
                            display: 'flex',
                            flexDirection: 'column'
                        }}
                    >
                        <Avatar
                            src={displayImage !== "" ? displayImage : user.displayImageUrl}
                            sx={{
                                height: 87,
                                mb: 2,
                                width: 87
                            }}
                        />
                        <Typography
                            gutterBottom
                            variant="h5"
                        >
                            {user.firstName} {user.lastName}
                        </Typography>
                        <Typography
                            color="text.secondary"
                            variant="body2"
                        >
                            {user.company}
                        </Typography>
                    </Box>
                </CardContent>
                <Divider />
                <CardActions>
                    <Button
                        fullWidth
                        variant="text"
                        component="label"
                    >
                        Upload Picture
                        <input
                            hidden type="file"
                            accept="image/*"
                            src={displayImage}
                            onChange={handleUpload}
                        />
                    </Button>
                </CardActions>
            </Card>
            <Toast
                toast={toast}
                handleCloseToast={handleCloseToast}
                message={"Whoops, there was a problem uploading the image!"}
            />
        </>
    );
}
