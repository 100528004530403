import {
    Button,
    Container,
    Grid,
    MenuItem,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import React, {useState} from 'react';
import { Controller, useForm } from 'react-hook-form';
import { AccountPermissionTypes } from '../../constants/userTypes';
import useCtx from '../../contexts/PerksContext';
import ImageUploader from '../../components/form/ImageUploader';
import getImageBase64 from '../../components/form/ConvertImage';



//-----------------------------------------------------------------------


export default function UserCreationPage() {
    const { control, handleSubmit, reset } = useForm();
    const { postDataWithAuth } = useCtx();
    const [imageURL, setImageURL] = useState('');

    const handleSubmitUser = async (data) => {
        try {
            const req = {};
            const base64ImageString = await getImageBase64(imageURL);
            req["displayImageUrl"] = base64ImageString;
            for (const key in data) {
                if (data[key] != null) {
                    req[key] = data[key];
                } 
            }
            const url = `user/new`;
            await postDataWithAuth(url, req);
            reset();
            alert(`Successfully created user`);
        } catch (error) {
            alert(`Failed to create user ${error}`);
        }
    }

    return (
        <Container>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12}>
                    <Typography variant="h4" align="center" gutterBottom>
                        Add User
                    </Typography>
                    <form onSubmit={handleSubmit(handleSubmitUser)}>
                        <Stack
                            spacing={2}
                            noValidate
                            autoComplete="off"
                        >
                            <Controller
                                name="firstName"
                                control={control}
                                defaultValue=""
                                render={({ field }) => <TextField {...field} label="First Name" fullWidth />}
                            />
                            <Controller
                                name="lastName"
                                control={control}
                                defaultValue=""
                                render={({ field }) => <TextField {...field} label="Last Name" fullWidth />}
                            />
                            <Controller
                                name="permissionType"
                                control={control}
                                defaultValue={AccountPermissionTypes[2]}
                                render={({ field }) => <TextField
                                    {...field}
                                    select
                                    label="Role"
                                >
                                    {
                                        AccountPermissionTypes.map((p, idx) => (
                                            <MenuItem key={idx} value={p}>
                                                {p}  
                                            </MenuItem>
                                        ))
                                    }
                                </TextField>}
                            />
                            <ImageUploader
                                 name="displayImageUrl"
                                 control={control}
                                 defaultValue={null}
                                 imageURL = {imageURL}
                                 setImageURL={setImageURL}
                            />
                            <Controller
                                name="email"
                                control={control}
                                defaultValue=""
                                render={({ field }) => <TextField {...field} label="Email" fullWidth />}
                            />
                            <Controller
                                name="phoneNumber"
                                control={control}
                                defaultValue=""
                                render={({ field }) => <TextField {...field} label="Phone Number (optional)" fullWidth />}
                            />
                            <Controller
                                name="gender"
                                control={control}
                                defaultValue=""
                                render={({ field }) => <TextField {...field} label="Gender (optional)" fullWidth />}
                            />
                            <Controller
                                name="company"
                                control={control}
                                defaultValue=""
                                render={({ field }) => <TextField {...field} label="Company (optional)" fullWidth />}
                            />
                            <Controller
                                name="voucherQuota"
                                control={control}
                                defaultValue="10"
                                render={({ field }) => <TextField {...field} label="Voucher Quota" fullWidth />}
                            />

                            <Button type="submit" variant="contained" color="primary">
                                Submit
                            </Button>
                        </Stack>
                    </form>
                </Grid>
            </Grid>
        </Container>
    )
}