import { Grid, Stack, InputAdornment } from '@mui/material';
import { useState } from 'react';
import { Toast } from '../../components/toast';
import useCtx from '../../contexts/PerksContext';
import { applySortFilter, getComparator } from '../../utils/sortFilter';
import { ProductFilterSidebar, ProductSort } from '../products';
import VoucherCard from './VoucherCard';
import VoucherClaimModal from './VoucherClaimModal';
import { StyledSearch } from '../../components/table/ListToolbar';
import Iconify from '../../components/iconify/Iconify';


// ----------------------------------------------------------------------


export default function VoucherList({ vouchers, brands, categories, claimedVoucher, setClaimedVoucher, searchTerm, setSearchTerm, ...other }) {

  const [open, setOpen] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('createdAt');
  const [brandFilters, setBrandFilters] = useState([]);
  const [categoryFilters, setCategoryFilters] = useState([]);
  const [toast, setToast] = useState(false);
  const { userId, postDataWithAuth } = useCtx();

  // SORTING AND FILTERING ----------------------------------------------------------------------

  var filteredVouchers = vouchers;

  if (brandFilters.length > 0 || categoryFilters.length > 0) {
    filteredVouchers = filteredVouchers.filter((v) => {
      return brandFilters.includes(v.brandId) || categoryFilters.includes(v.categoryId)
    });
  }

  const sortedVouchers = applySortFilter(filteredVouchers, getComparator(order, orderBy));

  const handleOpenFilter = () => {
    setOpenFilter(true);
  };
  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  // TOAST ----------------------------------------------------------------------

  const handleCloseToast = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setToast(false);
  }

  // CLAIMING ----------------------------------------------------------------------


  const handleClose = () => setOpen(false);

  const handleClaimVoucher = async (voucher) => {
    const claimURL = `voucher/claim`;
    try {
      const resp = await postDataWithAuth(claimURL, {
        voucherId: voucher.voucherId,
        userId: userId,
      });
      voucher.code = resp.voucherCode;
      setClaimedVoucher(voucher);
      setOpen(true);
    } catch (e) {
      setToast(true);
    }
  };


  // ----------------------------------------------------------------------


  return (
    <>
      <Stack direction="row" justifyContent="flex-end" spacing={1} flexShrink={2} sx={{ my: 1 }}>
        <StyledSearch
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder={"Search for voucher..."}
          startAdornment={
            <InputAdornment position="start">
              <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
            </InputAdornment>
          }
        />
      </Stack>
      <Stack direction="row" flexWrap="wrap-reverse" alignItems="center" justifyContent="flex-end" sx={{ mb: 5 }}>
        <Stack direction="row" spacing={1} flexShrink={2} sx={{ my: 1 }}>
          <ProductFilterSidebar
            openFilter={openFilter}
            onOpenFilter={handleOpenFilter}
            onCloseFilter={handleCloseFilter}
            brandFilters={brandFilters}
            setBrandFilters={setBrandFilters}
            brands={brands.filter((b) => b.status === 1)}
            categoryFilters={categoryFilters}
            setCategoryFilters={setCategoryFilters}
            categories={categories}
          />
          <ProductSort setOrder={setOrder} setOrderBy={setOrderBy} />
        </Stack>
      </Stack>


      <Grid container spacing={3} {...other}>
        {sortedVouchers.map((voucher, idx) => (
          <Grid key={idx} item xs={12} sm={6} md={3}>
            <VoucherCard
              voucher={voucher}
              handleClaimVoucher={(handleClaimVoucher)}
            />
          </Grid>
        ))}
      </Grid>

      <VoucherClaimModal
        open={open}
        handleClose={handleClose}
        claimedVoucher={claimedVoucher}
      />

      <Toast
        toast={toast}
        handleCloseToast={handleCloseToast}
        message={"You have reached the voucher's claim limit!"}
      />
    </>
  );
}