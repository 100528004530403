import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
// @mui
import {
  Button,
  Card,
  Container,
  FormControl,
  InputAdornment, InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography
} from '@mui/material';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { StyledRoot, StyledSearch } from '../components/table/ListToolbar';
import Iconify from '../components/iconify/Iconify';
import useCtx from '../contexts/PerksContext';
import { VoucherOverviewTable } from '../sections/vouchers/VoucherOverviewTable';
import CustomSpinner from '../components/spinner/Spinner';

export function ManageVouchersPage() {
  const [vouchers, setVouchers] = useState([]);
  const [brands, setBrands] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState(1);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('voucherId');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const { fetchPagedData } = useCtx();

  //--------------------------------------------------------------------------------------

  const handleSearchTermChange = (event) => {
    setPage(0);
    setSearchTerm(event.target.value);
  };

  const handleStatusFilterChange = (event) => {
    setPage(0);
    setStatusFilter(event.target.value);
  }

  //--------------------------------------------------------------------------------------

  useEffect(() => {
    const fetchData = async () => {

      try {
        const params = [
          { name: 'searchTerm', value: searchTerm },
        ];
        if (statusFilter >= 0) {
          params.push({ name: 'status', value: statusFilter })
        }
        params.push(
          { name: 'sortBy', value: orderBy },
          { name: 'sortOrder', value: order }
        );
        const vouchers = await fetchPagedData(`voucher/all-admin`,
          page + 1,
          rowsPerPage,
          ...params
        );
        setVouchers(vouchers.data);
        setTotalRecords(vouchers.totalRecords);

        const brands = await fetchPagedData(`brand/all`, null, null);
        setBrands(brands.data);
        setIsLoading(false);
      } catch (err) {
        console.error(`Failed to load data: ${err}`);
      }
    }
    fetchData();
  }, [searchTerm, statusFilter, order, orderBy, page, rowsPerPage])

  //---------------------------------------------------------------

  return isLoading ? <CustomSpinner/> : (
    <>
      <Helmet>
        <title> HCN Perks </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Manage Vouchers
          </Typography>
          <Button
            component={Link}
            to={`/dashboard/transactions/new-voucher`}
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}>
            New Voucher
          </Button>
        </Stack>

        <Card sx={{ marginBottom: "10%" }}>
          <ListToolbar
            searchTerm={searchTerm}
            handleSearchChange={handleSearchTermChange}
            statusFilter={statusFilter}
            handleStatusFilterChange={handleStatusFilterChange}
          />
          <VoucherOverviewTable
            vouchers={vouchers}
            brands={brands}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            order={order}
            setOrder={setOrder}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            totalRecords={totalRecords}
          />
        </Card>
      </Container>
    </>
  )
}



// LIST TOOL BAR ----------------------------------------------------------------------

ListToolbar.propTypes = {
  searchTerm: PropTypes.string,
  handleSearchChange: PropTypes.func,
  statusFilter: PropTypes.number,
  handleStatusFilterChange: PropTypes.func
};

function ListToolbar({ searchTerm, handleSearchChange, statusFilter, handleStatusFilterChange }) {
  return (
    <StyledRoot>
      <StyledSearch
        value={searchTerm}
        onChange={handleSearchChange}
        placeholder="Search vouchers..."
        startAdornment={
          <InputAdornment position="start">
            <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
          </InputAdornment>
        }
      />

      <FormControl sx={{ width: '200px' }}>
        <InputLabel id="permission-label">Status</InputLabel>
        <Select
          labelId="permission-label"
          value={statusFilter}
          label="Status"
          onChange={handleStatusFilterChange}
        >
          <MenuItem value={-1}>All</MenuItem>
          <MenuItem value={1}>Active</MenuItem>
          <MenuItem value={0} label="Inactive">Inactive</MenuItem>
          <MenuItem value={2} label="Redeemed">Fully Redeemed</MenuItem>
        </Select>
      </FormControl>

    </StyledRoot>
  );
}
