import { Box, Button, Modal, Stack, TextField, Typography, MenuItem } from '@mui/material';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { modalEditStyle } from '../../theme/modal';
import { AccountPermissionTypes } from '../../constants/userTypes';

//-----------------------------------------------------------------------

export default function UserEditModal({ open, handleClose, handleSubmitEdit, data }) {
    const { control, handleSubmit } = useForm();
    const user = data.user;
    return (
        <div>
            {user && (
                <Modal
                    open={open}
                    onClose={handleClose}
                >
                    <Box
                        noValidate
                        autoComplete="off"
                        sx={modalEditStyle}
                    >
                        <Typography
                            fontSize={30}
                            marginBottom={5}
                        >
                            Edit User
                        </Typography>
                        <form onSubmit={handleSubmit(handleSubmitEdit)}>
                            <Stack
                                spacing={2}
                                noValidate
                                autoComplete="off"
                            >
                                <Controller
                                    name="firstName"
                                    control={control}
                                    defaultValue={user.firstName}
                                    render={({ field }) => <TextField {...field} label="First Name" fullWidth />}
                                />
                                <Controller
                                    name="lastName"
                                    control={control}
                                    defaultValue={user.lastName ?? ""}
                                    render={({ field }) => <TextField {...field} label="Last Name" fullWidth />}
                                />
                                <Controller
                                    name="permissionType"
                                    control={control}
                                    defaultValue={user.permissionType}
                                    render={({ field }) => <TextField
                                        {...field}
                                        select
                                        label="Role"
                                    >
                                        {
                                            AccountPermissionTypes.map((p, idx) => (
                                                <MenuItem key={idx} value={p}>
                                                    {p}
                                                </MenuItem>
                                            ))
                                        }
                                    </TextField>}
                                />
                                <Controller
                                    name="email"
                                    control={control}
                                    defaultValue={user.email}
                                    render={({ field }) => <TextField {...field} label="Email" fullWidth />}
                                />
                                <Controller
                                    name="phoneNumber"
                                    control={control}
                                    defaultValue={user.phoneNumber}
                                    render={({ field }) => <TextField {...field} label="Phone Number" fullWidth />}
                                />
                                <Controller
                                    name="company"
                                    control={control}
                                    defaultValue={user.company ?? ""}
                                    render={({ field }) => <TextField {...field} label="Company" fullWidth />}
                                />
                                <Controller
                                    name="voucherQuota"
                                    control={control}
                                    defaultValue={user.voucherQuota}
                                    render={({ field }) => <TextField {...field} label="Voucher Quota" fullWidth />}
                                />

                                <Button type="submit" variant="contained" color="primary">
                                    Submit
                                </Button>
                            </Stack>
                        </form>
                    </Box>
                </Modal>

            )}

        </div>
    )
}