import { Container, Stack, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import CustomSpinner from '../../components/spinner/Spinner';
import { useVoucherCtx } from '../../contexts/VoucherContext';
import VoucherList from '../../sections/vouchers/VoucherList';

// ----------------------------------------------------------------------

export default function VouchersPage() {

    const { vouchers, filteredVouchers, brands, categories, claimedVoucher, setClaimedVoucher, isLoading, setSearchTerm, searchTerm } = useVoucherCtx();

    //------------------------------------------------------------------------


    return isLoading ? (
        <CustomSpinner />
    ) : (
        <>
            <Helmet>
                <title> HCN Perks </title>
            </Helmet>

            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4">
                        Vouchers
                    </Typography>
                </Stack>
                <VoucherList
                    vouchers={filteredVouchers}
                    brands={brands}
                    categories={categories}
                    claimedVoucher={claimedVoucher}
                    setClaimedVoucher={setClaimedVoucher}
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                />
            </Container>
        </>
    );
}
