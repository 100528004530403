import { createContext, useContext, useEffect, useState } from "react"
import useCtx from "./PerksContext";

const VoucherContext = createContext();

function getViewPermissions(userPermissionType) {
    switch (userPermissionType) {
        case "Admin":
            return [0, 1, 2];
        case "Staff":
            return [0, 1];
        case "Creator":
            return [0, 2];
        default:
            return [999]; // if permission is missing, display nothing
    }
}

export function VoucherProvider({ children }) {
    const { userId, fetchDataWithAuth, fetchPagedData } = useCtx();
    const [vouchers, setVouchers] = useState([]);
    const [filteredVouchers, setFilteredVouchers] = useState([]);
    const [brands, setBrands] = useState([]);
    const [categories, setCategories] = useState([]);
    const [claimedVoucher, setClaimedVoucher] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState("");

    useEffect(() => {
        const fetchData = async () => {
            try {
                const user = await fetchDataWithAuth(`user/${userId}`);
                const viewPermissions = getViewPermissions(user.permissionType);
                let vouchers = [];
                for (let i = 0 ; i < viewPermissions.length ; i++) {
                    const batch = await fetchDataWithAuth(`voucher/all?status=1&viewPermission=${viewPermissions[i]}`);
                    vouchers = vouchers.concat(batch);
                }
                const brands = await fetchPagedData("brand/all", 1, 100);
                const categories = await fetchDataWithAuth("category/all");

                // vouchers contain not only voucher details, but also brand and category details
                for (let i = 0; i < vouchers.length; i++) {
                    const v = vouchers[i];
                    const brand = brands.data.find((b) => b.brandId === v.brandId);
                    v.brand = brand.name;
                    v.brandId = brand.brandId; // required for filtering
                    const category = categories.find(c => c.categoryId === brand.categoryId);
                    v.category = category.name;
                    v.categoryId = category.categoryId; // required for filtering
                    vouchers[i] = v;
                }

                setVouchers(vouchers);
                setFilteredVouchers(vouchers);
                setBrands(brands.data);
                setCategories(categories);
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
        fetchData();
    }, [claimedVoucher]);

    useEffect(() => {
        var filteredVouchers = vouchers.filter((voucher) => {
            return voucher.brand.toLowerCase().includes(searchTerm.toLowerCase());
        })
        setFilteredVouchers(filteredVouchers);
    }, [searchTerm])


    return (
        <VoucherContext.Provider value={{
            vouchers,
            filteredVouchers,
            brands,
            categories,
            claimedVoucher,
            setClaimedVoucher,
            isLoading,
            setSearchTerm,
            searchTerm
        }}>
            {children}
        </VoucherContext.Provider>
    )
}

export function useVoucherCtx() {
    return useContext(VoucherContext);
}