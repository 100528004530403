import { Box, Button, Modal, Stack, TextField, Typography } from '@mui/material';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { modalEditStyle } from '../../theme/modal';

//-----------------------------------------------------------------------

export default function BrandEditModal({ open, handleClose, handleSubmitEdit, data }) {
    const { control, handleSubmit } = useForm();
    const brand = data.brand;
    return (
        <div>
            {brand && (
                <Modal
                    open={open}
                    onClose={handleClose}
                >
                    <Box
                        noValidate
                        autoComplete="off"
                        sx={modalEditStyle}
                    >
                        <Typography
                            fontSize={30}
                            marginBottom={5}
                        >
                            Edit Brand
                        </Typography>
                        <form onSubmit={handleSubmit(handleSubmitEdit)}>
                            <Stack
                                spacing={2}
                                noValidate
                                autoComplete="off"
                            >
                                <Controller
                                    name="name"
                                    control={control}
                                    defaultValue={brand.name}
                                    render={({ field }) => <TextField {...field} label="Name" fullWidth />}
                                />
                                <Controller
                                    name="description"
                                    control={control}
                                    defaultValue={brand.description}
                                    render={({ field }) => <TextField {...field} label="Description" fullWidth />}
                                />
                                <Controller
                                    name="imageUrl"
                                    control={control}
                                    defaultValue={brand.imageUrl}
                                    render={({ field }) => <TextField {...field} label="Image Url" fullWidth />}
                                />

                                <Button type="submit" variant="contained" color="primary">
                                    Submit
                                </Button>
                            </Stack>
                        </form>
                    </Box>
                </Modal>

            )}

        </div>
    )
}