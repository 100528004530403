export const modalClaimStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '40%',
    height: '60%',
    borderRadius: 3,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    '@media (max-width: 768px)': {
        width: '90%',
        height: '75%',
        maxWidth: 400,
    },
    overflowY: 'auto', // Enable vertical scrolling
};

export const modalEditStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60%', 
    height: '90%',
    borderRadius: 3,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    overflowY: 'auto', // Enable vertical scrolling
    '@media (max-width: 768px)': {
        maxWidth: 250, // Adjust for smaller screens if necessary
        height: '90%',
    },
};
