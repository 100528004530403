import CryptoJS from "crypto-js";


export function getMembershipId(userId, joinDate) {
    const userIdString = userId.toString();
    const timestampString = Math.floor(joinDate.getTime() / 1000).toString();

    // Combine the userId and timestamp
    const combinedString = userIdString + timestampString;

    // Generate an MD5 hash of the combined string
    const hash = CryptoJS.MD5(combinedString).toString().toUpperCase();

    // Truncate or pad the hash to 15 characters
    const len = 15;
    return hash.length > len ? hash.substring(0, len) : hash.padEnd(len, '0');
}