import { Close } from '@mui/icons-material';
import { IconButton, Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import * as React from 'react';
import { Fragment } from 'react';

export default function Toast ({ toast, handleCloseToast, message }) {

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    const action = (
        <Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleCloseToast}
            >
                <Close fontSize="small" />
            </IconButton>
        </Fragment>
    );

    return (
        <Snackbar
            open={toast}
            action={action}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
            <Alert
                severity="error"
                onClose={handleCloseToast}
            >
                {message}
            </Alert>
        </Snackbar>
    );
}