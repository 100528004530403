import { Dialog, DialogTitle, DialogContent, Button, DialogActions } from "@mui/material";

export default function ConfirmationDialog({
    isConfirmationOpen,
    handleCloseConfirmation,
    handleConfirm,
    title,
    message,
}) {
    return (
        <Dialog open={isConfirmationOpen} onClose={handleCloseConfirmation}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                {message}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseConfirmation} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleConfirm} color="primary">
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    )
}