import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, Link } from '@mui/material';

// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
  const theme = useTheme();

  const logo = (
    <Box
      ref={ref}
      component="div"
      sx={{
        width: 60,
        height: 60,
        display: 'inline-flex',
        ...sx,
      }}
      {...other}
    >
      <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
        width="260.000000pt" height="50.000000pt" viewBox="0 0 260.000000 116.000000"
        preserveAspectRatio="xMidYMid meet">

        <g transform="translate(0.000000,116.000000) scale(0.100000,-0.100000)"
          fill="#000000" stroke="none">
          <path d="M2340 1146 c-38 -8 -56 -17 -58 -28 -4 -23 27 -708 33 -717 5 -9 267
36 276 47 3 4 3 35 1 70 -4 60 -5 62 -30 62 -15 0 -44 -4 -64 -9 -26 -6 -40
-5 -46 3 -5 6 -15 137 -22 291 -6 154 -14 283 -16 287 -3 5 -8 7 -12 7 -4 -1
-32 -6 -62 -13z"/>
          <path d="M2116 1107 l-59 -10 7 -176 c3 -97 9 -229 13 -295 l6 -118 61 7 c34
3 65 10 69 15 8 7 -9 551 -18 578 -2 6 -8 11 -12 11 -4 -1 -35 -6 -67 -12z"/>
          <path d="M1894 1068 l-52 -9 -68 -210 c-37 -115 -72 -208 -77 -206 -5 1 -42
81 -83 177 -42 96 -77 177 -80 179 -5 5 -124 -16 -131 -23 -5 -4 14 -547 23
-664 l6 -73 46 7 c97 14 92 3 85 171 -3 86 -2 145 3 140 5 -5 38 -65 74 -135
36 -69 70 -127 75 -129 6 -2 32 59 59 135 73 205 68 195 77 147 4 -22 8 -89 8
-149 l1 -109 63 7 c34 4 66 11 71 16 9 9 -11 716 -21 733 -5 8 -10 8 -79 -5z"/>
          <path d="M1045 919 c-27 -5 -74 -14 -104 -20 l-53 -11 6 -246 c4 -136 9 -269
12 -295 l6 -49 52 7 c85 12 89 16 81 90 -8 74 -9 73 98 95 115 23 181 103 181
220 0 82 -16 128 -57 168 -54 52 -106 62 -222 41z m119 -158 c12 -13 16 -32
14 -57 -4 -45 -36 -73 -95 -81 l-40 -5 -6 53 c-4 30 -7 62 -7 72 0 37 104 51
134 18z"/>
          <path d="M645 848 c-60 -11 -114 -24 -119 -28 -9 -9 12 -721 22 -732 4 -4 25
-3 47 1 22 5 84 17 138 26 l98 17 -3 67 -3 66 -35 -1 c-19 0 -51 -2 -70 -5
l-35 -4 -6 65 c-4 36 -3 70 2 76 6 7 26 15 47 18 102 18 93 10 90 84 l-3 67
-30 0 c-16 -1 -48 -4 -71 -8 l-41 -7 -6 67 c-4 40 -3 71 4 79 5 7 32 15 59 19
76 10 84 20 75 93 -8 73 3 70 -160 40z"/>
          <path d="M358 798 l-57 -10 -3 -152 -3 -151 -60 -12 c-33 -7 -65 -11 -72 -8
-8 2 -13 48 -15 144 -2 76 -5 142 -8 144 -3 3 -34 -1 -70 -7 l-65 -12 0 -40
c0 -116 17 -643 21 -667 5 -24 10 -27 42 -26 20 1 51 4 67 8 l30 6 0 150 0
150 65 11 c36 7 68 10 72 7 5 -2 8 -33 8 -68 0 -35 3 -66 8 -68 4 -2 35 0 70
6 l62 11 -1 130 c-3 248 -14 459 -24 461 -5 1 -36 -2 -67 -7z"/>
        </g>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return (
    <Link to="/dashboard/vouchers" component={RouterLink} sx={{ display: 'contents' }}>
      {logo}
    </Link>
  );
});

Logo.propTypes = {
  sx: PropTypes.object,
  disabledLink: PropTypes.bool,
};

export default Logo;
