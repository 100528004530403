export const AccountStatusTypes = [
    { name: "inactive", id: 0 },
    { name: "active", id: 1 },
];

export const AccountPermissionTypes = [
    "Admin",
    "Staff",
    "Creator",
];

export const UserPermissionTypes = {
    Admin: 'Admin',
    Staff: 'Staff',
    Creator: 'Creator'
}