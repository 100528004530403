import PropTypes from 'prop-types';
// @mui
import { styled, alpha } from '@mui/material/styles';
import { Toolbar, Tooltip, IconButton, Typography, OutlinedInput, InputAdornment, Select, MenuItem, InputLabel, FormControl } from '@mui/material';
// component
import Iconify from '../../components/iconify';

// ----------------------------------------------------------------------

export const StyledRoot = styled(Toolbar)(({ theme }) => ({
    height: 96,
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 1, 0, 3),
}));

export const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
    width: 240,
    transition: theme.transitions.create(['box-shadow', 'width'], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.shorter,
    }),
    '&.Mui-focused': {
        width: 320,
        boxShadow: theme.customShadows.z8,
    },
    '& fieldset': {
        borderWidth: `1px !important`,
        borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
    },
}));

// ----------------------------------------------------------------------

ListToolbar.propTypes = {
    numSelected: PropTypes.number,
    filterName: PropTypes.string,
    onFilterName: PropTypes.func,
    placeholder: PropTypes.string,
    tableName: PropTypes.string,
    filterPermission: PropTypes.string,
    handleFilterByPermission: PropTypes.func
};

export function ListToolbar({ numSelected, filterName, tableName, onFilterName, placeholder, filterPermission, handleFilterByPermission }) {
    return (
        <StyledRoot
            sx={{
                ...(numSelected > 0 && {
                    color: 'primary.main',
                    bgcolor: 'primary.lighter',
                }),
            }}
        >
            {numSelected > 0 ? (
                <Typography component="div" variant="subtitle1">
                    {numSelected} selected
                </Typography>
            ) : (
                <StyledSearch
                    value={filterName}
                    onChange={onFilterName}
                    placeholder={placeholder}
                    startAdornment={
                        <InputAdornment position="start">
                            <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                        </InputAdornment>
                    }
                />
            )}


            {numSelected > 0 ? (
                <Tooltip title="Delete">
                    <IconButton>
                        <Iconify icon="eva:trash-2-fill" />
                    </IconButton>
                </Tooltip>
            ) : (
                <Tooltip>
                    {tableName === "transactions" && (
                        <>
                            <FormControl sx={{ width: '200px' }}>
                                <InputLabel id="permission-label">User Role</InputLabel>
                                <Select
                                    labelId="permission-label"
                                    value={filterPermission}
                                    label="Permission"
                                    onChange={handleFilterByPermission}
                                >
                                    <MenuItem value={"Staff,Creator"} label="Creators And Staff">Staff and Creators</MenuItem>
                                    <MenuItem value={"All"}>All</MenuItem>
                                    <MenuItem value={"Staff"}>Staff</MenuItem>
                                    <MenuItem value={"Creator"} label="Creators">Creators</MenuItem>
                                    <MenuItem value={"Admin"} label="Admins">Admins</MenuItem>
                                </Select>
                            </FormControl>
                        </>
                    )}

                </Tooltip>
            )}
        </StyledRoot>
    );
}
