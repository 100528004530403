import {
  Checkbox,
  IconButton,
  MenuItem,
  Popover,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';
import dayjs from 'dayjs';
import { useState } from 'react';
import getImageBase64 from '../../components/form/ConvertImage';
import Iconify from '../../components/iconify/Iconify';
import Label from '../../components/label';
import ListHead from '../../components/table/ListHead';
import { VoucherStatus } from '../../constants/voucherTypes';
import useCtx from '../../contexts/PerksContext';
import { fDateTime } from '../../utils/formatTime';
import { GetLocation } from '../../utils/location';
import VoucherCodesModal from './VoucherCodesModal';
import VoucherEditModal from './VoucherEditModal';
import VoucherClaimModal from './VoucherClaimModal';

//---------------------------------------------------------------------------------

const headers = [
  { id: 'voucherId', label: 'ID' },
  { id: 'name', label: 'Voucher' },
  { id: 'brandName', label: 'Brand' },
  { id: 'totalVouchers', label: 'Total Vouchers' },
  { id: 'vouchersClaimed', label: 'Vouchers Claimed' },
  { id: 'vouchersRemaining', label: 'Vouchers Remaining' },
  { id: 'status', label: 'Status' },
  { id: 'expiresAt', label: 'Expires At' },
  { id: 'createdAt', label: 'Created At' },
  { id: 'updatedAt', label: 'Updated At' },

  { id: "" }
];

export function VoucherOverviewTable({
  vouchers,
  brands,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  order,
  setOrder,
  orderBy,
  setOrderBy,
  totalRecords,
}) {
  const { fetchDataWithAuth, putDataWithAuth } = useCtx();
  const [selected, setSelected] = useState([]);
  const [open, setOpen] = useState(false);
  const [codesModal, setCodesModal] = useState(false);
  const [codes, setCodes] = useState([]);
  const [imageURL, setImageURL] = useState(null);
  const [editModal, setEditModal] = useState(false);
  const [locations, setLocations] = useState([]);
  const [openPreview, setOpenPreview] = useState(false);

  // TABLE ---------------------------------------------------------------

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = vouchers.map((n) => n.voucherId);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  // SIDE MENU -------------------------------------------------------------------------

  const handleOpenMenu = (event, voucher) => {
    setOpen({
      target: event.currentTarget,
      voucher: voucher,
    });
  };

  const handleCloseMenu = () => {
    setOpen({});
    setOpenPreview(false);
  };

  const handleOpenModal = async () => {
    setCodes(await fetchDataWithAuth(`vouchercode/all?voucherId=${open.voucher.voucherId}`))
    setCodesModal(true);
  }

  const handleCloseModal = () => {
    setCodesModal(false);
    handleCloseMenu();
  }

  // EDIT STATUS -------------------------------------------------------------------------

  const handleStatusChange = async (status) => {
    try {
      await putDataWithAuth(`voucher/edit/${open.voucher.voucherId}`, {
        status: status,
      });
      alert('Success! Please refresh the page to see the change');
      handleCloseMenu();
    } catch (e) {
      alert(`Operation failed. Please contact the developer`);
      console.error(`Failed to change voucher status: ${e.response.data}`);
    }
  }

  // EDIT DETAILS -------------------------------------------------------------------------

  const handleOpenEditModal = () => {
    setEditModal(true);
  }

  const handleCloseEditModal = () => {
    setEditModal(false);
    handleCloseMenu();
  }

  const handleSubmitEdits = async (data) => {
    try {
      const url = `voucher/edit/${open.voucher.voucherId}`;
      var newImage = imageURL;
      if (newImage && newImage !== data.imageUrl) {
        newImage = await getImageBase64(newImage);
      }
      var checkedLocations = await fillCoordinates(locations);
      const body = {
        brandId: data.brandId ?? null,
        name: data.name ?? null,
        imageUrl: newImage,
        viewPermission: data.viewPermission ?? null,
        customClaimText: data.customClaimText === "" || !data.customClaimText ? null : data.customClaimText,
        userClaimLimit: data.userClaimLimit ?? null,
        termsAndConditions: data.termsAndConditions ?? null,
        locations: checkedLocations, // take locations instead of data.locations because state changes only affect locations
        expiresAt: data.hasExpiry ? new Date(new Date(data.expiryDate).getTime() - new Date().getTimezoneOffset() * 60000) : null,
      }
      await putDataWithAuth(url, body);
      alert("Successfully updated voucher! Please refresh the page to see the changes");
      handleCloseEditModal();
    } catch (e) {
      alert(`Failed to update voucher: ${e}`)
    }
  }

  const handlePreviewClick = () => {
    setOpenPreview(true);
    var temp = open;
    temp.voucher.code = '-- PREVIEW --'
    setOpen(temp);
  }

  // fills missing coordinates
  const fillCoordinates = async (locations) => {
    for (let i = 0; i < locations.length; i++) {
      const location = locations[i];
      if (!location.longitude || !location.latitude || location.longitude === 0 || location.latitude === 0) {
        const resp = await GetLocation(location.postalCode);
        location.longitude = resp["LONGITUDE"];
        location.latitude = resp["LATITUDE"];
      }
      locations[i] = location;
    }
    return locations;
  }

  //----------------------------------------------------------------------------------

  return (

    <div>

      <TableContainer sx={{ minWidth: 1000, overflowX: 'auto' }}>
        <Table>
          <ListHead
            order={order}
            orderBy={orderBy}
            headLabel={headers}
            rowCount={vouchers.length}
            numSelected={selected.length}
            onRequestSort={handleRequestSort}
            onSelectAllClick={handleSelectAllClick}
          />
          <TableBody>
            {vouchers.map((voucher) => {
              const {
                voucherId,
                name,
                brandName,
                totalVouchers,
                vouchersClaimed,
                vouchersRemaining,
                status,
                createdAt,
                updatedAt,
                expiresAt,
              } = voucher;
              const selectedVoucher = selected.indexOf(voucherId) !== -1;

              return (
                <TableRow hover key={voucherId} tabIndex={-1} role="checkbox" selected={selectedVoucher}>
                  <TableCell padding="checkbox" size='small' sx={{ width: '5%' }}>
                    <Checkbox checked={selectedVoucher} onChange={(event) => handleClick(event, voucherId)} />
                  </TableCell>

                  <TableCell component="th" scope="row" padding="none" sx={{ width: '5%' }}>
                    <Stack direction="row" alignItems="center" spacing={2}>
                      <Typography variant="subtitle2" noWrap>
                        {voucherId}
                      </Typography>
                    </Stack>
                  </TableCell>
                  <TableCell align="left" sx={{ width: '30%' }}>{name}</TableCell>
                  <TableCell align="left" size='small' sx={{ width: '10%' }}>{brandName}</TableCell>
                  <TableCell align="left" size='small' sx={{ width: '10%' }}>{totalVouchers}</TableCell>
                  <TableCell align="left" size='small' sx={{ width: '10%' }}>{vouchersClaimed}</TableCell>
                  <TableCell align="left" size='small' sx={{ width: '10%' }}>{vouchersRemaining}</TableCell>
                  <TableCell align="left">
                    {status === 1 ? (
                      <Label color={'success'}>Active</Label>
                    ) : status === 2 ? (
                      <Label color={'warning'}>Fully Redeemed</Label>
                    ) : (
                      <Label color={'error'}>Disabled</Label>
                    )}
                  </TableCell>
                  <TableCell align="left" size='small' sx={{ width: '10%' }}>{expiresAt ? fDateTime(dayjs(expiresAt)) : "No expiry"}</TableCell>
                  <TableCell align="left" size='small' sx={{ width: '10%' }}>{fDateTime(dayjs(createdAt))}</TableCell>
                  <TableCell align="left" size='small' sx={{ width: '10%' }}>{fDateTime(dayjs(updatedAt))}</TableCell>
                  <TableCell align="right">
                    <IconButton size="large" color="inherit" onClick={(event) => handleOpenMenu(event, voucher)}>
                      <Iconify icon={'eva:more-vertical-fill'} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={totalRecords}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <Popover
        open={Boolean(open.target)}
        anchorEl={open.target}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem onClick={handleOpenModal}>
          <Iconify icon={'eva:book-open-fill'} sx={{ mr: 2 }} />
          View codes
        </MenuItem>

        <MenuItem onClick={handleOpenEditModal}>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem>

        {open.voucher && open.voucher.status === VoucherStatus.Inactive ? (
          <MenuItem onClick={() => handleStatusChange(VoucherStatus.Active)}>
            <Iconify icon={'eva:checkmark-circle-fill'} sx={{ mr: 2 }} />
            Enable
          </MenuItem>
        ) : open.voucher && open.voucher.status === VoucherStatus.Active ? (
          <MenuItem onClick={() => handleStatusChange(VoucherStatus.Inactive)}>
            <Iconify icon={'eva:checkmark-circle-fill'} sx={{ mr: 2 }} />
            Disable
          </MenuItem>
        ) : (
          <></>
        )}

        <MenuItem onClick={handlePreviewClick}>
          <Iconify icon={'eva:eye-fill'} sx={{ mr: 2 }} />
          Preview
        </MenuItem>
      </Popover>

      <VoucherCodesModal
        open={open}
        modal={codesModal}
        handleCloseModal={handleCloseModal}
        codes={codes}
      />

      {Object.keys(open).length > 0 && (
        <VoucherEditModal
          editModal={editModal}
          handleCloseEditVoucher={handleCloseEditModal}
          handleSubmitEdits={handleSubmitEdits}
          voucher={open.voucher}
          brands={brands}
          imageURL={imageURL}
          setImageURL={setImageURL}
          locations={locations}
          setLocations={setLocations}
        />
      )}

      {Object.keys(open).length > 0 && (
        <VoucherClaimModal 
          open={openPreview}
          handleClose={handleCloseMenu}
          claimedVoucher={open.voucher}
        />
      )}


    </div>
  )
}


